import React from 'react'
import './RegisterButton.css'
import { Link } from 'react-router-dom';

const RegisterButton = () => {
  return (
    <div>
     {
       window.Telegram.WebApp.platform != 'unknown' ?
       <Link to="/Register"
       className='RegisterButtonLuncher'>
       Register
      </Link>
       :
       <Link to="/Register"
         className='RegisterButton'>
         Register
        </Link>
     }
         
    </div>
  )
}

export default RegisterButton