
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './ViolatingChannel.css'
import { setBottomSheetViolatingChannel } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
const ViolatingChannel = () => {
    const dispatch = useDispatch()
    const BottomSheetViolatingChannel = useSelector((state) => state.Home.BottomSheetViolatingChannel)

    const onDismiss = () => {
        setTimeout(() => {
        dispatch(setBottomSheetViolatingChannel(false))
    }, 300);
    }
  return (
    <BottomSheet onDismiss={onDismiss} open={BottomSheetViolatingChannel}>
    <div className='ViolatingChannel'>
        <div className='ViolatingChannelTitle'>
        Violating channel
        </div>
        <div className='ViolatingChannelDes'>
        You can not order members for this channel/group due to violation
        of our terms of services. If a mistake is made, please contact support.
        </div>
        <div className='ViolatingChannelFooter'>
        <button className='ViolatingChannelButton2'  onClick={() => dispatch(setBottomSheetViolatingChannel(false))}>
            Got it
            </button>
            <button className='ViolatingChannelButton' 
           
            >
                Support
            </button>
           
        </div>
    </div>
</BottomSheet>
  )
}

export default ViolatingChannel