import React from 'react'
import './Transfer.css'
import Arrow from './../Public/Image/BuyCoin/Arrow.svg'
import Coin from './../Public/Image/BuyCoin/Coin.svg'
import Snackbar from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment';

const TransferHistoryItemSend = ({ item }) => {
    const dateAndTimeArray = item.date.split(' ');
    const datePart = dateAndTimeArray[0];
    const timePart = dateAndTimeArray[1];
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const copyText = async (value) => {
        // Get the input element
        // console.log(value)
        try {
            // Copy text to clipboard
            await navigator.clipboard.writeText(value);
            // console.log('Text copied to clipboard:', value);
            handleClick({ vertical: 'bottom', horizontal: 'center' })

        } catch (err) {
            console.error('Unable to copy text to clipboard', err);
        }
    }
    return (
        <div className='TransferHistoryBodyItem'>
            <div className='TransferHistoryInnerItem'>
                <div className='TransferHistoryInnerItemKey'>Status</div>
                <div className='TransferHistoryInnerItemValue'>
                    Transferred
                    <img src={Arrow} className='StatusImgTransfer' />
                </div>
            </div>

            <div className='TransferHistoryInnerItem'>
                <div className='TransferHistoryInnerItemKey'>Transfered coins</div>
                <div className='TransferHistoryInnerItemValue'>
                    {parseInt(item.coin).toLocaleString()}
                    <img src={Coin} className='StatusImgTransfer' />
                </div>
            </div>

            <div className='TransferHistoryInnerItem'>
                <div className='TransferHistoryInnerItemKey'>Transfer to</div>
                <div className='TransferHistoryInnerItemValue'>
                    {item.reciever_email}

                </div>
            </div>

            <div className='TransferHistoryInnerItemTime'>
                <div className='TransferHistoryInnerItemKey'>Transfer time</div>
                <div className='TransferHistoryInnerItemValueCoulmn'>
                    <div className='DateDay'>
                    {
                                                // item.date
                                                moment(item.dateTime).calendar(null, {
                                                    sameDay: '[Today] , HH : MM',
                                                    nextDay: '[Tomorrow]',
                                                    nextWeek: 'dddd',
                                                    lastDay: '[Yesterday] , HH : MM',
                                                    // lastWeek: '[Last] dddd',
                                                    lastWeek: 'DD MMM , YYYY',
                                                    sameElse: 'DD MMM , YYYY'
                                                })
                                            }
                        {/* {datePart} */}
                    </div>
                    <div className='DateTime'>
                        {/* {timePart} */}
                    </div>
                </div>
            </div>

            <div className='TransferHistoryInnerItem'>
                <div className='TransferHistoryInnerItemKey'>Tracking number</div>
                <div className='TransferHistoryInnerItemValueBlue' onClick={(e) => copyText(item._id)}>
                    {
                        item._id.length > 15 ?
                            item._id.substring(0, 15) + '...' :
                            item._id
                    }
                    <ContentCopyIcon className='CopyIDOrder' />

                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                // message={` Password changed`}
                key={vertical + horizontal}
                autoHideDuration={3000}
                message={<span id="client-snackbar">
                    {/* <CheckCircleIcon className='CheckIcon'/> */}
                    Tracking number was copied</span>}

            />
        </div>
    )
}

export default TransferHistoryItemSend