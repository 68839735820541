import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Tab: 'Member',
  Countries: 'World',
  PostViewFilter: 1,
  PackageData: [],
  Coin: 0,
  ButtonStatusNotice: false,
  CheckBoxNotice: false,
  BottomSheetCheckUsername: false,
  UserNameForOrder: '',
  PostLink: '',
  BottomSheetPriceAndChannel: false,
  BottomSheetUnsuccessfulPayment: false,
  BottomSheetsuccessfulPayment: false,
  BottomSheetTooManyOrders: false,
  BottomSheetTooManyOrdersUser: false,
  BottomSheetTooManyOrdersUserTitle: false,
  BottomSheetTooManyOrdersUserDescription: false,
  BottomSheetViolatingChannel: false,
  UserCoin: 0,
  ViewPackageData: [],
  InsufficientBalance: false,
  BottomSheetViewShowPriceAndChannel: false,
  BottomSheetViewCheckUsername: false,
  Loading: false,
  MiniLoading: false,
  TooManyLogin: false,
  UserFromApi: [],
  IsIranian: undefined,
  Update: false,
  UpdateVersion: '',
  SaleTerminatingSessionsBSh: false,
  SaleTerminatedSessionsBSh: false,
  SaleTwoStepBottomSheet: false,
  SaleBannedUserBottomSheet: false,
  SaleTooManyAttempts: false,
  SalePendingVerificationBSh:false
}

export const Home = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    SetTab: (state, action) => {
      state.Tab = action.payload
    },
    SetCountry: (state, action) => {
      state.Countries = action.payload
    },
    SetPackageData: (state, action) => {
      state.PackageData = action.payload
    },
    SetPostViewFilter: (state, action) => {
      state.PostViewFilter = action.payload
    },
    setButtonStatusNotice: (state, action) => {
      state.ButtonStatusNotice = action.payload
    },
    setCheckBoxNotice: (state, action) => {
      state.CheckBoxNotice = action.payload
    },
    setBottomSheetCheckUsername: (state, action) => {
      state.BottomSheetCheckUsername = action.payload
    },
    setUserNameForOrder: (state, action) => {
      state.UserNameForOrder = action.payload
    },
    setPostLink: (state, action) => {
      state.PostLink = action.payload
    },
    setBottomSheetPriceAndChannel: (state, action) => {
      state.BottomSheetPriceAndChannel = action.payload
    },
    setBottomSheetUnsuccessfulPayment: (state, action) => {
      state.BottomSheetUnsuccessfulPayment = action.payload
    },
    setBottomSheetsuccessfulPayment: (state, action) => {
      state.BottomSheetsuccessfulPayment = action.payload
    },
    setBottomSheetTooManyOrders: (state, action) => {
      state.BottomSheetTooManyOrders = action.payload
    },
    setBottomSheetTooManyOrdersUser: (state, action) => {
      state.BottomSheetTooManyOrdersUser = action.payload
    },
    setBottomSheetTooManyOrdersUserTitle: (state, action) => {
      state.BottomSheetTooManyOrdersUserTitle = action.payload
    },
    setBottomSheetTooManyOrdersUserDescription: (state, action) => {
      state.BottomSheetTooManyOrdersUserDescription = action.payload
    },

    setBottomSheetViolatingChannel: (state, action) => {
      state.BottomSheetViolatingChannel = action.payload
    },
    setUserCoin: (state, action) => {
      state.UserCoin = action.payload
    },
    setViewPackageData: (state, action) => {
      state.ViewPackageData = action.payload
    },
    setInsufficientBalance: (state, action) => {
      state.InsufficientBalance = action.payload
    },
    setBottomSheetViewShowPriceAndChannel: (state, action) => {
      state.BottomSheetViewShowPriceAndChannel = action.payload
    },
    setBottomSheetViewCheckUsername: (state, action) => {
      state.BottomSheetViewCheckUsername = action.payload
    },
    setLoading: (state, action) => {
      state.Loading = action.payload
    },
    setMiniLoading: (state, action) => {
      state.MiniLoading = action.payload
    },
    setTooManyLogin: (state, action) => {
      state.TooManyLogin = action.payload
    },
    setUserFromApi: (state, action) => {
      state.UserFromApi = action.payload
    },
    setIsIranian: (state, action) => {
      state.IsIranian = action.payload
    },
    SetUpdate: (state, action) => {
      state.Update = action.payload
    },
    SetUpdateVersion: (state, action) => {
      state.UpdateVersion = action.payload
    },
    SetSaleTerminatingSessionsBSh: (state, action) => {
      state.SaleTerminatingSessionsBSh = action.payload
    },
    SetSaleTwoStepBottomSheet: (state, action) => {
      state.SaleTwoStepBottomSheet = action.payload
    },
    SetSaleBannedUserBottomSheet: (state, action) => {
      state.SaleBannedUserBottomSheet = action.payload
    },
    SetSaleTerminatedSessionsBSh: (state, action) => {
      state.SaleTerminatedSessionsBSh = action.payload
    },
    SetSaleTooManyAttempts: (state, action) => {
      state.SaleTooManyAttempts = action.payload
    },
    SetSalePendingVerificationBSh: (state, action) => {
      state.SalePendingVerificationBSh = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { SetTab, SetCountry, SetPackageData, SetPostViewFilter, setUserFromApi, setIsIranian,
  setButtonStatusNotice, setCheckBoxNotice, setBottomSheetCheckUsername, SetUpdate, SetUpdateVersion,
  setUserNameForOrder, setBottomSheetPriceAndChannel, setTooManyLogin,SetSaleTerminatedSessionsBSh,SetSaleTooManyAttempts,
  setBottomSheetUnsuccessfulPayment, setBottomSheetsuccessfulPayment,SetSalePendingVerificationBSh,
  setBottomSheetTooManyOrders, setBottomSheetTooManyOrdersUser, SetSaleTerminatingSessionsBSh, SetSaleTwoStepBottomSheet, SetSaleBannedUserBottomSheet,
  setBottomSheetTooManyOrdersUserTitle, setBottomSheetTooManyOrdersUserDescription, setBottomSheetViolatingChannel, setUserCoin, setViewPackageData,
  setInsufficientBalance, setBottomSheetViewShowPriceAndChannel, setBottomSheetViewCheckUsername, setLoading, setMiniLoading, setPostLink } = Home.actions
export default Home.reducer