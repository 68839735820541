import React from 'react'
import { useState, useEffect } from 'react';

import BottomNavigation from './../Home/BottomNavigation'
import './Profile.css'
import { db } from './../DataBase/db'
import { useLiveQuery } from "dexie-react-hooks";
import { useSelector, useDispatch } from 'react-redux'
import { SetEmail } from './../app/Slice'
import { SetNameAccount } from './../app/Slice'
import { SetPicture } from './../app/Slice'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
// import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useHistory } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag"
import { Link } from 'react-router-dom';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import { GetProfile } from './../Api/GetProfile'
import Skeleton from '@mui/material/Skeleton';
import { AllApi } from './../Api/AllApi'


import { phone } from 'phone';
import { GetCookie } from './../Action/GetCookie'
import LockIcon from '@mui/icons-material/Lock';
import { UpdateCheck } from './../Action/UpdateCheck'
import { SetUpdate } from './../app/Home'
import { SetUpdateVersion } from './../app/Home'

const Profile = () => {
    let AuthState = GetCookie('Token')
    const history = useHistory();

    useEffect(() => {

        if (AuthState == null || AuthState == 'null') {
            history.push(`/`);
        }


    }, [AuthState])

    const dispatch = useDispatch()
    const Email = useSelector((state) => state.counter.email)
    const NameAccount = useSelector((state) => state.counter.NameAccount)
    const Picture = useSelector((state) => state.counter.Picture)
    const [PhoneNumber, setPhoneNumber] = useState(null)
    const [Flag, setFlag] = useState('')
    const Data = useLiveQuery(
        () => db.Data.toArray()
    );



    const Token = GetCookie('Token');
    const GetProfileFunction = async () => {
        // let res = await GetProfile(Token)
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.GetProfile(Token)
        // console.log(res)
        if (res.code == 200) {
            dispatch(SetEmail(res.data.user.email))
            dispatch(SetNameAccount(res.data.user.nameAccount))
            // console.log(res)
            // nameAccount
            if (res.data.user.phonenumber) {
                // console.log(res.data.user.phonenumber)
                setPhoneNumber(phone(res.data.user.phonenumber))
            } else {
                // console.log(res.data.user.phonenumber)

                setPhoneNumber(false)

            }

            // dispatch(setUserCoin(res.data.user.coins))
            dispatch(SetEmail(res.data.user.email))
            let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })

            // console.log(phone(res.data.user.phonenumber))
        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }

    }

    useEffect(() => {
        GetProfileFunction()
    }, [])



    useEffect(() => {
        if (Data) {
            if (Data.length > 0) {
                // console.log(Data)
                dispatch(SetEmail(Data[0].Email))
                dispatch(SetPicture(Data[0].Picture))
                // setPhoneNumber(Data[0].PhoneNumber)
                setFlag(Data[0].CountryCode)
            }
        }

    }, [Data])


    const GetPageProfile = (Target) => {
        history.push(`/${Target}`);
    }
    useEffect(() => {
        CheckUpdateAccess()
    }, [])

    async function CheckUpdateAccess() {
        let UpdateRes = await UpdateCheck()
        let DissmissedVersion = GetCookie('DissMissUpdateVersion')
        //    console.log(DissmissedVersion)
        //    console.log(UpdateRes.version)
        dispatch(SetUpdateVersion(UpdateRes.version))
        if (UpdateRes.status == true && Number(DissmissedVersion) != UpdateRes.version) {
            dispatch(SetUpdate(true))

        }
    }
    // console.log(PhoneNumber)
    // console.log(Picture)

    return (
        <div className='Profile'>
            <div className={PhoneNumber == false || PhoneNumber == null ? 'ProfileHeaderWithoutPhone' : 'ProfileHeader'} >

                <div>
                    {Picture ?
                        PhoneNumber == null ?
                            <Skeleton variant="circular" width={48} height={48} animation="wave" />
                            :
                            <img className="ProfileHeaderImg" src={Picture} />
                        :
                        <div className='ProfilePhotoTextContainer'>
                            {
                                PhoneNumber == null ?
                                    <Skeleton variant="circular" width={48} height={48} animation="wave" />
                                    : <span className='ProfilePhotoText AccountText' style={{ "width": "44px", "height": "44px" }}>

                                        {Email.charAt(0).toUpperCase()}
                                    </span>
                            }

                        </div>
                    }

                </div>
                <div className='NameAccountDiv'>
                    {
                        NameAccount ?
                            NameAccount
                            :
                            null
                    }
                </div>
                <div className={PhoneNumber == false || PhoneNumber == null ? 'ProfileHeaderEmail' : 'ProfileHeaderEmailwithoutPhone'}>
                    {PhoneNumber == null ?
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={153} height={10} animation="wave" />
                        :
                        Email
                    }
                </div>
                <div className='ProfileHeaderPhone'>
                    {
                        PhoneNumber == null ?

                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={121} height={10} animation="wave" />
                            :
                            PhoneNumber == false ?
                                null
                                :

                                <>
                                    < ReactCountryFlag
                                        // key={j}
                                        countryCode={Flag ? Flag : PhoneNumber.countryIso2}
                                        className='FlagPhoneNumber'
                                        style={{ "marginRight": "4px" }}
                                        svg
                                        title={Flag ? Flag : PhoneNumber.countryIso2}
                                    />

                                    {/* {phone(PhoneNumber)} */}
                                    {PhoneNumber.phoneNumber}
                                </>



                    }

                </div>
                {
                    // PhoneNumber ?
                    <div className='ProfileHeaderPhoneEdit'>
                        {
                            // PhoneNumber == false || PhoneNumber == null ?
                                // <Link to={`/AddNumber`} className='ProfileHeaderPhoneEditButton' >
                                //     <AddOutlinedIcon className='EditOutlinedIcon1' />  Add number
                                // </Link>
                                // null

                                // :
                                // <Link to={`/EditNumber`} className='ProfileHeaderPhoneEditButton' >
                                //     <EditOutlinedIcon className='EditOutlinedIcon1' />  Edit Number
                                // </Link>  
                                 <Link to={`/EditProfile`} className='ProfileHeaderPhoneEditButton' >
                                    <EditOutlinedIcon className='EditOutlinedIcon1' />  Edit profile
                                </Link>
                        }

                    </div>
                    // : null
                }
            </div>
            <div className={PhoneNumber == false || PhoneNumber == null ? 'ProfileContentWithoutPhone' : 'ProfileContent'}>
                <div className='ProfileTextIcon' onClick={() => GetPageProfile("AccountInformation")}>
                    <BarChartRoundedIcon className='ProfileIconColor' />   Account Information
                </div>
                <div className='ProfileTextIcon' onClick={() => GetPageProfile("Payments")}>
                    <PaymentRoundedIcon className='ProfileIconColor' />  Payments
                </div>
                {/* <div className='ProfileTextIcon' onClick={() => GetPageProfile("Notifications")}>
                    <NotificationsNoneOutlinedIcon className='ProfileIconColor' />Notifications</div> */}
                <div className='ProfileTextIcon' onClick={() => GetPageProfile("Privacy")}>
                    <LockIcon className='ProfileIconColor' />   Privacy and security
                </div>
                <a className='ProfileTextIcon' href='https://membersgram.com/google/google/'>
                    <HelpOutlineRoundedIcon className='ProfileIconColor' />Support</a>


            </div>
            <div className='LogoutProfile'>
                <div className='ProfileTextIcon' onClick={() => GetPageProfile("LogOut")}>
                    <LogoutOutlinedIcon className='ProfileIconColor' />   Log out
                </div>
            </div>
            <div className='NavContainer'>
                <BottomNavigation />
            </div>
        </div >
    )
}

export default Profile