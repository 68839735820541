
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store'
import { Provider } from 'react-redux'

import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
     <Provider store={store}>
      {/* https://315065911817-fcea4akijbt9382to96f3h5or50ucebs.apps.googleusercontent.com */}
      <GoogleOAuthProvider clientId="490978921152-eahmgen2ihogmfd6issj4p4b2u88v4dv.apps.googleusercontent.com">
      {/* <GoogleOAuthProvider clientId="https://315065911817-a8otgo84dp4cgd0rb5df3o6a124dk2oh.apps.googleusercontent.com"> */}
          <App />
        </GoogleOAuthProvider>
     
     </Provider>
   
  // </React.StrictMode>
);
reportWebVitals();
