import React from 'react'
import BackImg from './../Public/Image/Login/Back.svg'
import { Link } from 'react-router-dom';
import './ForgetPassword.css'
import TextField from '@mui/material/TextField';
import { useState } from 'react';
// import {ForgetPasswordGetCodeApi} from './../Api/ForgetPasswordGetCodeApi'
import { AllApi } from './../Api/AllApi'
import { setTooManyLogin } from './../app/Home'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { setMiniLoading } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
const ForgetPasswordGetCode = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const Email = searchParams.get('email');
    const [Code, setCode] = useState('')
    const HandleCodeChange = (code) => {
        setCode(code)
        setCodeEmpty(false)
        setCodeInvalid(false)
    }
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const dispatch = useDispatch()
    const [CodeEmpty, setCodeEmpty] = useState(false)
    const [CodeInvalid, setCodeInvalid] = useState(false)

    const CheckCode = async() => {
        dispatch(setMiniLoading(true))

        let status = true
        if (Code.length == 0) {
            setCodeEmpty(true)
            status = false
        }
      
        if(status == true){
            const MakeApiClass = new AllApi();
            let Res=await MakeApiClass.ForgetPasswordGetCodeApi(Email,Code)
            // let Res =await ForgetPasswordGetCodeApi(Email,Code)
            // console.log(Res)
            if (Res.code == 429) {
                dispatch(setTooManyLogin(true)) 
            }else if(Res.code === 200){
                history.push(`/ChangePassword?key=${Res.data.key}&&email=${Email}`);
                // {
                //     "status": "success",
                //     "data": {
                //         "key": "e2668b7e4b2a890f2e6682bb650c5940"
                //     },
                //     "message": null,
                //     "code": 200,
                //     "methode": 3
                // }
            }else if(Res.code == 301){
                // code  301 :کاربری با این ایمیل یافت نشد
                // setEmailNotFound(true)
                setCodeInvalid(true)
            }else if(Res.code === 302){
                // Code 302 : کدوارد شده اشتباه است
                setCodeInvalid(true)
            }else if(Res.code === 300){
                // code  300 : Invalid value
                setCodeInvalid(true)

            }  else if (Res.code == 120 || Res.code == 121 || Res.code == 122 || Res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))
    
            }
        }
        dispatch(setMiniLoading(false))

    }
    return (
        <div className='ForgetPasswordContainer'>
            <div className='ForgetPasswordHeader'>
                <Link to="/ForgetPassword" className='ForgetPasswordBackIcon'>
                    <img src={BackImg} />
                </Link>
                <div className='ForgetPasswordHeaderText'>
                Enter code
                </div>
            </div>
            <div className='ForgetPasswordBody'>
                <div className='ForgetPasswordBodyText'>
                Please enter verification code sent to your Email
                </div>
                <div className='LoginEmailInput'>
                    {
                        CodeEmpty ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                label="Verification code"
                                value={Code}
                                helperText="Code must not be empty"
                                className='LoginEmailTextField'
                                onChange={(e) => HandleCodeChange(e.target.value)}
                            />
                            :
                            CodeInvalid?
                            <TextField
                            error
                            id="outlined-error-helper-text"
                            label="Verification code"
                            value={Code}
                            helperText="Verification code is incorrect"
                            className='LoginEmailTextField'
                            onChange={(e) => HandleCodeChange(e.target.value)}
                        />
                            :
                                <TextField id="outlined-basic" label="Verification code" value={Code}
                                    onChange={(e) => HandleCodeChange(e.target.value)}
                                    variant="outlined" className='LoginEmailTextField' />
                    }


                </div>
            </div>
          <TooManyRequestBSh />

            <div className='ForgetPasswordFooter'>
                <div className='ForgetPasswordLinkDiv'>
                    <button className="ForgetPasswordLink" onClick={() => CheckCode()}>
                    {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Confirm'
                        }
                    
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ForgetPasswordGetCode