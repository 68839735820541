import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  email: '',
  NameAccount:'',
  Picture:'',
  Authentication: 'WaitPhoneNumber',
  PhoneNumber: '',
  PhoneCode: '',
  Session: '',
  PhoneCodeHash: ''
}

export const Slice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      if (state.value < 3) {
        state.value += 1
      }
      // else{
      //   state.value=0
      // }
    },
    decrement: (state) => {
      if (state.value > 0) {
        state.value -= 1
      }
      // else{
      //     state.value=3
      // }

    },
    SetValue:(state,action)=>{
      state.value = action.payload
    },
    SetEmail: (state, action) => {
      state.email = action.payload
    },
    SetNameAccount: (state, action) => {
      state.NameAccount = action.payload
    },
    SetPicture: (state, action) => {
      state.Picture = action.payload
    },
    UpdateStateTelegram: (state, action) => {
      state.Authentication = action.payload
    },
    SaveSession: (state, action) => {
      state.Session = action.payload
    },
    SetPhoneNumber: (state, action) => {
      state.PhoneNumber = action.payload
    },
    SetPhoneCodeHash: (state, action) => {
      state.PhoneCodeHash = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement,SetValue, SetEmail,SetPicture,UpdateStateTelegram,
   SaveSession,SetPhoneNumber,SetPhoneCodeHash,SetNameAccount} = Slice.actions
export default Slice.reducer