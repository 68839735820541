import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  CongratulationsBottomSheet: false,
  CongratulationsBottomSheet2: false,
  CongratulationsBottomSheet3: false,
  PromotionBottomSheet: false,
  GetNameBottomSheet: false,
  AppSettingData: [],
  UserLeagueData: [],
  MyLeagueRank: 'Bronze III',
  MyLeagueRankNumber: 1,
  LeagueLimitJoinProgress: 0,
  LeagueMinimumJoinProgress: 0,
  RewardLeague: '',
  GiftTopjoin: [],
  NameAccount: false,
  JoinCountRedux: -1,
  UserReward: '',
  ClaimRewardCall: false,
  DataClaimReward: false,
  ChangeLeagueAndReqApi: false,
  LeagueEnd: false
}
export const Game = createSlice({
  name: 'Game',
  initialState,
  reducers: {
    SetCongratulationsBottomSheet: (state, action) => {
      state.CongratulationsBottomSheet = action.payload
    },
    SetCongratulationsBottomSheet2: (state, action) => {
      state.CongratulationsBottomSheet2 = action.payload
    },
    SetCongratulationsBottomSheet3: (state, action) => {
      state.CongratulationsBottomSheet3 = action.payload
    },
    SetPromotionBottomSheet: (state, action) => {
      state.PromotionBottomSheet = action.payload
    },
    SetGetNameBottomSheet: (state, action) => {
      state.GetNameBottomSheet = action.payload
    },
    SetAppSettingData: (state, action) => {
      state.AppSettingData = action.payload
    },
    SetUserLeagueData: (state, action) => {
      state.UserLeagueData = action.payload
    },
    SetMyLeagueRank: (state, action) => {
      state.MyLeagueRank = action.payload
    },
    SetLeagueLimitJoinProgress: (state, action) => {
      state.LeagueLimitJoinProgress = action.payload
    },
    SetLeagueMinimumJoinProgress: (state, action) => {
      state.LeagueMinimumJoinProgress = action.payload
    },

    SetRewardLeague: (state, action) => {
      state.RewardLeague = action.payload
    },
    SetMyLeagueRankNumber: (state, action) => {
      state.MyLeagueRankNumber = action.payload
    },
    SetGiftTopjoin: (state, action) => {
      state.GiftTopjoin = action.payload
    },
    SetNameAccount: (state, action) => {
      state.NameAccount = action.payload
    },
    SetJoinCountRedux: (state, action) => {
      state.JoinCountRedux = action.payload
    },

    SetUserReward: (state, action) => {
      state.UserReward = action.payload
    },
    SetClaimRewardCall: (state, action) => {
      state.ClaimRewardCall = action.payload
    },

    SetDataClaimReward: (state, action) => {
      state.DataClaimReward = action.payload
    },
    SetChangeLeagueAndReqApi: (state, action) => {
      state.ChangeLeagueAndReqApi = action.payload
    },
    
    SetLeagueEnd: (state, action) => {
      state.LeagueEnd = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { SetCongratulationsBottomSheet,
  SetCongratulationsBottomSheet2,
  SetCongratulationsBottomSheet3,
  SetPromotionBottomSheet,
  SetGetNameBottomSheet,
  SetAppSettingData, SetUserLeagueData,
  SetMyLeagueRank,
  SetLeagueLimitJoinProgress, SetLeagueMinimumJoinProgress,
  SetRewardLeague, SetMyLeagueRankNumber,
  SetGiftTopjoin, SetNameAccount,
  SetJoinCountRedux,
  SetUserReward, SetClaimRewardCall, SetDataClaimReward
  , SetChangeLeagueAndReqApi,SetLeagueEnd
} = Game.actions
export default Game.reducer