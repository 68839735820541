import React from 'react'
import './GameCoin.css'


import { useSelector, useDispatch } from 'react-redux'

import { AllApi } from '../../Api/AllApi'
import { GameDB } from '../../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';
import { useState, useRef, useEffect } from 'react';
import { SetUserLeagueData } from '../../app/Game'
import { SetMyLeagueRank } from '../../app/Game'
import { SetLeagueLimitJoinProgress } from '../../app/Game'
import { SetLeagueMinimumJoinProgress } from '../../app/Game'

import { SetRewardLeague } from '../../app/Game'
import { SetMyLeagueRankNumber } from '../../app/Game'
import { SetGiftTopjoin } from '../../app/Game'
import { SetNameAccount } from '../../app/Game'
import { SetJoinCountRedux } from '../../app/Game'
import { SetPromotionBottomSheet } from '../../app/Game'
import { SetUserReward } from '../../app/Game'
import { SetClaimRewardCall } from '../../app/Game'
import { SetDataClaimReward } from '../../app/Game'
import Promotion from '../../Game/BottomSheets/Promotion'
import { SetAppSettingData } from '../../app/Game'
import Congratulations from '../../Game/BottomSheets/Congratulations'
import Congratulations2 from '../../Game/BottomSheets/Congratulations2'
import Congratulations3 from '../../Game/BottomSheets/Congratulations3'
import { SetCongratulationsBottomSheet } from './../../app/Game'
import { SetCongratulationsBottomSheet2 } from './../../app/Game'
import { SetCongratulationsBottomSheet3 } from './../../app/Game'
import { Link } from 'react-router-dom';
import InnerGame from './InnerGame'
import Loading from '../../Loading'
import Skeleton from '@mui/material/Skeleton';
const GameCoin = () => {
    const dispatch = useDispatch()
    const [MyLeague, setMyLeague] = useState(true)
    const AppSettingData = useSelector((state) => state.Game.AppSettingData)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
    const ChangeLeagueAndReqApi = useSelector((state) => state.Game.ChangeLeagueAndReqApi)
    const LeagueEnd = useSelector((state) => state.Game.LeagueEnd)
    const GiftTopjoin = useSelector((state) => state.Game.GiftTopjoin)

    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const LeagueLimitJoinProgress = useSelector((state) => state.Game.LeagueLimitJoinProgress)
    const RewardLeague = useSelector((state) => state.Game.RewardLeague)
    const NameAccount = useSelector((state) => state.Game.NameAccount)
    const [LoadState, setLoadState] = useState(true)
    const [LastUpdate, setLastUpdate] = useState(0)
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };

    const [open, setOpen] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setOpen(false)
        }, 300);
    }
    const CalcTimeStart = (time) => {
        let NowInNow = new Date().getTime();
        let Distance = time - NowInNow;
        var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
        minutes = String(minutes).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");
        return { minutes, seconds }
    }
    const GetProfile = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetProfile()
        // console.log(Result)

        if (Result.code == 200) {

            Data = Result
        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    const GetAppSetting = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.appSetting()
        // console.log(Result)
        if (Result.code == 200) {
            Data = Result
        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }

    const GetleagueListJoinsfunc = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetleagueListJoins()
        // console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    const GetAllListJoinsfunc = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetAllListJoins()
        // console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data



    }
    const CheckForClaimReward = async () => {
        // console.log('claim check')
        // console.log(JoinCountRedux)
        let JR = JoinCountRedux

        let AppSetting = await GameDB.AppSetting.toArray()
        let Profile = await GameDB.Profile.toArray()
        let LeagueLevelProfile
        if (Profile.length > 0) {

            LeagueLevelProfile = Profile[0].Profile.leagueLevel
        }
        if (AppSetting.length > 0) {

            let LeaguesAppSetting = AppSetting[0].AppSetting.leagues
            // console.log(LeaguesAppSetting)
            LeaguesAppSetting.map((item, i) => {
                let min = item.min
                let max = item.max ? item.max : false
                let PrevIndex
                if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i }

                if (min <= JR && JR < max) {
                    // console.log(LeagueLevelProfile)
                    // console.log(item.leagueLevel)
                    if (LeagueLevelProfile == item.leagueLevel) {
                        // no need to claim reward

                        // console.log(PrevIndex)
                    } else {
                        // need to claim reward
                        if (item.leagueLevel - LeagueLevelProfile > 0) {
                            let Comparelevel = item.leagueLevel - LeagueLevelProfile
                            console.log(item.leagueLevel)
                            console.log(LeagueLevelProfile)
                            dispatch(SetUserReward(item))
                            let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                            console.log(dataClaim)
                            dispatch(SetDataClaimReward(dataClaim))
                            dispatch(SetPromotionBottomSheet(true))
                        }

                    }
                } else if (min <= JR && max == false) {
                    if (LeagueLevelProfile == item.leagueLevel) {
                        // no need to claim reward

                        console.log(PrevIndex)
                    } else {
                        // need to claim reward
                        if (item.leagueLevel - LeagueLevelProfile > 0) {
                            let Comparelevel = item.leagueLevel - LeagueLevelProfile
                            console.log(item.leagueLevel)
                            console.log(LeagueLevelProfile)
                            dispatch(SetUserReward(item))
                            let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                            console.log(dataClaim)
                            dispatch(SetDataClaimReward(dataClaim))
                            dispatch(SetPromotionBottomSheet(true))
                        }

                    }
                }
            })


        }
    }
    useEffect(() => {
        CheckForClaimReward()
    }, [JoinCountRedux])
    useEffect(() => {
        // console.log(ClaimRewardCall)
        if (ClaimRewardCall == true) {
            OnlineRequestAppSetting()
            OnlineRequestProfile()
            OnlineRequestLeagueList()

        }

        return () => {

        }
    }, [ClaimRewardCall])

    useEffect(() => {

        setLoadState(true)
        GameDB.AppSetting.toArray(function (AppSettingItem) {
            if (AppSettingItem.length > 0) {
                if (AppSettingItem[0].LastTime != false) {
                    let ResCalc = CalcTimeStart(AppSettingItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {
                        GameDB.AppSetting.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        OnlineRequestAppSetting()
                    } else {
                        OfflineRequestAppSetting()
                    }
                } else {
                    OnlineRequestAppSetting()
                }
            } else {
                OnlineRequestAppSetting()
            }
        });

        GameDB.Profile.toArray(function (ProfileItem) {
            if (ProfileItem.length > 0) {
                if (ProfileItem[0].LastTime != false) {
                    let ResCalc = CalcTimeStart(ProfileItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {
                        GameDB.Profile.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        OnlineRequestProfile()
                    } else {
                        OfflineRequestProfile()
                    }
                } else {
                    OnlineRequestProfile()
                }
            } else {
                OnlineRequestProfile()
            }
        });

        GameDB.LeagueList.toArray(function (LeagueListItem) {
            if (LeagueListItem.length > 0) {
                if (LeagueListItem[0].LastTime != false) {
                    let ResCalc = CalcTimeStart(LeagueListItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {

                        GameDB.LeagueList.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        OnlineRequestLeagueList()
                    } else {
                        // console.log('OfflineRequestLeagueList')
                        OfflineRequestLeagueList()
                    }
                } else {


                    OnlineRequestLeagueList()
                }
            } else {


                OnlineRequestLeagueList()
            }
        });

        GameDB.TopJoiner.toArray(function (TopJoinerItem) {
            if (TopJoinerItem.length > 0) {
                if (TopJoinerItem[0].LastTime != false) {
                    let ResCalc = CalcTimeStart(TopJoinerItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {

                        GameDB.TopJoiner.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        OnlineRequestTopJoiner()
                    } else {
                        // console.log('OfflineRequestTopJoiner')
                        OfflineRequestTopJoiner()
                    }
                } else {


                    OnlineRequestTopJoiner()
                }
            } else {


                OnlineRequestTopJoiner()
            }
        });


        dispatch(SetClaimRewardCall(false))
        setTimeout(() => {
            setLoadState(false)
        }, 100);


    }, [])
    useEffect(() => {
        if (ChangeLeagueAndReqApi == true) {
            OnlineRequestTopJoiner()

        }
    }, [ChangeLeagueAndReqApi])

    // App Setting
    const [NoInternet, setNoInternet] = useState(false)
    useEffect(() => {
        // console.log(window.navigator.onLine)
        if (NoInternet == true) {

            const timer = setInterval(async () => {
                let IsOnline = window.navigator.onLine
                // console.log(IsOnline)
                if (IsOnline == true) {
                    clearInterval(timer);
                    OnlineRequestAppSetting()
                    OnlineRequestProfile()
                    OnlineRequestLeagueList()
                }
            }, 1000);
            // console.log(NoInternet)
        }
    }, [NoInternet])

    const OnlineRequestAppSetting = async () => {
        // console.log('OnlineRequestAppSetting')
        setNoInternet(false)

        // let res = await ClaimReward()
        // console.log(res)
        let AppSettingRes = await GetAppSetting()
        if (AppSettingRes != 'Network') {
            let CashTime = AppSettingRes.data.cashTime
            let NowInNow = new Date().getTime();
            let LastTime = CashTime * 1000 + NowInNow;
            GameDB.AppSetting.toArray(function (AppSettingItem) {
                if (AppSettingItem.length > 0) {
                    GameDB.AppSetting.toCollection().modify(App => {
                        App.LastTime = LastTime;
                        // App.LastUpdate = NowInNow
                        App.AppSetting = AppSettingRes.data.appSetting
                    });
                } else {
                    GameDB.AppSetting.add({ AppSetting: AppSettingRes.data.appSetting, LastTime, LastUpdate: NowInNow });
                }

            });
        } else {
            handleClick({ vertical: 'bottom', horizontal: 'center' })
            OfflineRequestAppSetting()
        }

    }
    const OfflineRequestAppSetting = async () => {
        // console.log('OfflineRequestAppSetting')
        let AppSetting = await GameDB.AppSetting.toArray()
        if (AppSetting.length > 0) {
            // console.log(AppSetting[0])
            // console.log(AppSetting[0].AppSetting.leagues)


        } else {
            // console.log('error app')
            setNoInternet(true)
        }


    }
    //  Profile
    const OnlineRequestProfile = async () => {
        // console.log('OnlineRequestProfile')
        let GetProfileRes = await GetProfile()
        // console.log(GetProfileRes)
        if (GetProfileRes != 'Network') {
            let CashTime = GetProfileRes.data.cashTime
            dispatch(SetNameAccount(GetProfileRes.data.user.nameAccount))
            dispatch(SetJoinCountRedux(GetProfileRes.data.user.joinCount))

            let NowInNow = new Date().getTime();
            let LastTime = CashTime * 1000 + NowInNow;
            GameDB.Profile.toArray(function (ProfileItem) {
                if (ProfileItem.length > 0) {
                    GameDB.Profile.toCollection().modify(ProfileItem => {
                        ProfileItem.LastTime = LastTime;
                        ProfileItem.Profile = GetProfileRes.data.user

                    });
                } else {
                    GameDB.Profile.add({ Profile: GetProfileRes.data.user, LastTime, LastUpdate: NowInNow });
                }

            });
        } else {
            handleClick({ vertical: 'bottom', horizontal: 'center' })
            OfflineRequestProfile()
        }
    }
    const OfflineRequestProfile = async () => {
        console.log('OfflineRequestProfile')
        let Profile = await GameDB.Profile.toArray()
        console.log(Profile)
        console.log(Profile.length)
        if (Profile.length > 0) {

            dispatch(SetNameAccount(Profile[0].Profile.nameAccount))
            dispatch(SetJoinCountRedux(Profile[0].Profile.joinCount))

        } else {
            console.log('error')
            setNoInternet(true)

        }

    }
    // League List
    const [runInterval, setRunInterval] = useState(false);
    const [counter, setCounter] = useState(0);
    const [LastTime, setLastTime] = useState(0);
    const [CashTime, setCashTime] = useState(0);
    const intervalRef = useRef(null);


    useEffect(() => {

        if (runInterval) {
            intervalRef.current = setInterval(() => {
                let NowInNow = new Date().getTime();
                let LastTimeLeague = localStorage.getItem('LastTimeLeague')
                let Distance = LastTimeLeague - NowInNow;
                // var minutescashTime = Math.floor(CashTime / 60);
                var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
                // console.log(minutes, seconds)

                if (minutes < 0) {
                    setLastUpdate(Math.abs(minutes))
                } else {
                    setLastUpdate(false)

                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef.current);
        }; // clear interval when unmounting the component
    }, [runInterval, LastTime, CashTime]);

    const OnlineRequestLeagueList = async () => {
        console.log('OnlineRequestLeagueList')

        let GetleagueListJoinsRes = await GetleagueListJoinsfunc()
        console.log(GetleagueListJoinsRes)
        if (GetleagueListJoinsRes != 'Network') {
            let LevelUser = false
            LevelUser = GetleagueListJoinsRes.data.data.renkUser.leagueLevel


            let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)

            let CashTime1 = GetleagueListJoinsRes.data.data.cashTime
            // console.log(CashTime)
            dispatch(SetUserLeagueData(GetleagueListJoinsRes.data.data))
            dispatch(SetJoinCountRedux(GetleagueListJoinsRes.data.user.joinCount))
            let NowInNow = new Date().getTime();
            let LastTime = CashTime1 * 1000 + NowInNow;
            // let resTimer = await LeagueInterval(LastTime,CashTime,'Online')
            clearInterval(intervalRef.current);
            localStorage.setItem('LastTimeLeague', LastTime)

            setLastTime(LastTime)
            setCashTime(CashTime1)
            setRunInterval(true)

            // console.log(LastTime)
            GameDB.LeagueList.toArray(function (LeagueListItem) {
                // console.log(LeagueListItem)
                if (LeagueListItem.length > 0) {
                    GameDB.LeagueList.toCollection().modify(LeagueListItem => {

                        // LeagueListItem={ LeagueList: GetleagueListJoinsRes.data, LastTime:LastTime, LastUpdate: NowInNow }
                        LeagueListItem.LastTime = LastTime;
                        LeagueListItem.LeagueList = GetleagueListJoinsRes.data;
                    });
                } else {
                    GameDB.LeagueList.add({ LeagueList: GetleagueListJoinsRes.data, LastTime: LastTime, LastUpdate: NowInNow });
                }

            });
        } else {
            console.log(GetleagueListJoinsRes)
            console.log('OfflineRequestLeagueList')
            handleClick({ vertical: 'bottom', horizontal: 'center' })
            OfflineRequestLeagueList()


        }


    }
    const OfflineRequestLeagueList = async () => {
        console.log('OfflineRequestLeagueList')

        let LeagueList = await GameDB.LeagueList.toArray()
        if (LeagueList.length > 0) {
            console.log(LeagueList[0])
            dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
            let LevelUser = false
            LevelUser = LeagueList[0].LeagueList.data.renkUser.leagueLevel

            let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)

            dispatch(SetJoinCountRedux(LeagueList[0].LeagueList.user.joinCount))
            let cashTime = LeagueList[0].LeagueList.data.cashTime
            let LastTime = LeagueList[0].LastTime
            clearInterval(intervalRef.current);
            localStorage.setItem('LastTimeLeague', LastTime)
            setLastTime(LastTime)
            setCashTime(cashTime)
            setRunInterval(true)

            // timer(true, false, false);
            // clearInterval(count.current);
            // localStorage.setItem('StopIntervalLeague',false)
            // timer(false, LastTime, cashTime)
            // timer1 = setInterval(() => {
            //     let NowInNow = new Date().getTime();
            //     // console.log(LastTime)
            //     let Distance = LastTime - NowInNow;
            //     var minutescashTime = Math.floor(cashTime / 60);
            //     var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
            //     var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
            //     console.log(minutes, seconds)
            //     console.log(minutescashTime)
            //     if (minutes < 0) {
            //         setLastUpdate(Math.abs(minutes))
            //     } else {
            //         setLastUpdate(false)

            //     }
            // }, 1000);
        } else {
            console.log('error le')
            setNoInternet(true)

        }

    }

    const GetUserDataFromAppSetting = async (LevelUser) => {
        let AppSetting = await GameDB.AppSetting.toArray()
        console.log(AppSetting)
        if (AppSetting.length == 1) {
            // console.log(1)
            // console.log(AppSetting[0].AppSetting)
            // dispatch(SetAppSettingData(AppSetting[0].AppSetting)) 
            let RewardArray = AppSetting[0].AppSetting.leagues

            if (LevelUser != false) {
                RewardArray.map(item => {

                    if (item.leagueLevel == LevelUser) {

                        dispatch(SetRewardLeague(item))
                    }
                })
                dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

                let LeaguesArray = AppSetting[0].AppSetting.leagues
                LeaguesArray.map(item => {
                    if (item.leagueLevel == LevelUser) {

                        dispatch(SetMyLeagueRank(item.leagueName))
                        dispatch(SetLeagueLimitJoinProgress(item.max))
                        dispatch(SetLeagueMinimumJoinProgress(item.min))

                        dispatch(SetMyLeagueRankNumber(item.leagueLevel))

                    }
                })
            }
        } else {
            // console.log(2)
            const timer = setInterval(async () => {
                AppSetting = await GameDB.AppSetting.toArray()
                // console.log(AppSetting)
                if (AppSetting.length == 1) {
                    clearInterval(timer);
                    GetUserDataFromAppSetting(LevelUser)
                } else {
                    console.log(147852)
                }

            }, 1000);

        }


    }
    // League List
    // TopJoiner
    const OnlineRequestTopJoiner = async () => {
        console.log('OnlineRequestTopJoiner')
        let TopJoinerRes = await GetAllListJoinsfunc()
        console.log(TopJoinerRes)
        if (TopJoinerRes != 'Network') {
            let LevelUser = false
            LevelUser = TopJoinerRes.data.data.renkUser.leagueLevel


            // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)

            let CashTime1 = TopJoinerRes.data.data.cashTime
            // console.log(CashTime)
            // dispatch(SetUserLeagueData(TopJoinerRes.data.data))
            let NowInNow = new Date().getTime();
            let LastTime = CashTime1 * 1000 + NowInNow;
            // let resTimer = await LeagueInterval(LastTime,CashTime,'Online')
            // clearInterval(intervalRef.current);
            // localStorage.setItem('LastTimeLeague', LastTime)

            // setLastTime(LastTime)
            // setCashTime(CashTime1)
            // setRunInterval(true)
            GameDB.TopJoiner.toArray(function (TopJoinerItem) {
                if (TopJoinerItem.length > 0) {
                    GameDB.TopJoiner.toCollection().modify(App => {
                        App.LastTime = LastTime;
                        // App.LastUpdate = NowInNow
                        App.TopJoiner = TopJoinerRes.data.data.topUsers
                        App.renkUser = TopJoinerRes.data.data.renkUser
                    });
                } else {
                    GameDB.TopJoiner.add({ TopJoiner: TopJoinerRes.data.data.topUsers, renkUser: TopJoinerRes.data.data.renkUser, LastTime, LastUpdate: NowInNow });
                }

            });
        } else {

            console.log('OfflineRequestLeagueList')
            handleClick({ vertical: 'bottom', horizontal: 'center' })
            OfflineRequestTopJoiner()


        }

    }
    const OfflineRequestTopJoiner = async () => {
        console.log('OfflineRequestTopJoiner')
        let TopJoiner = await GameDB.TopJoiner.toArray()
        console.log(TopJoiner)
        dispatch(SetJoinCountRedux(TopJoiner[0].renkUser.joinCount))
    }

    // check Times AppSetting

    const [Time, setTime] = useState('')
    const [IsBetweenLeaguge, setIsBetweenLeaguge] = useState(null)



    const TimerAppSetting = async () => {
        let curTime = Date.now();
        let AppSetting = await GameDB.AppSetting.toArray()
        let betweenTime = AppSetting[0].AppSetting.timebetweenLeague - curTime
        let finaltime = AppSetting[0].AppSetting.timeFinishLeague - curTime



        //  check Time for show in ui



        const betweenTimeseconds = Math.floor(betweenTime / 1000);
        const betweenTimeminutes = Math.floor(betweenTimeseconds / 60);
        const betweenTimehours = Math.floor(betweenTimeminutes / 60);
        const betweenTimedays = Math.floor(betweenTimehours / 24);
        const remainingSeconds = betweenTimeseconds % 60;
        const remainingMinutes = betweenTimeminutes % 60;
        const remainingHours = betweenTimehours % 24;
        let m, h, s, day;
        m = String(remainingMinutes).padStart(2, "0");
        h = String(remainingHours).padStart(2, "0");
        s = String(remainingSeconds).padStart(2, "0");
        day = String(betweenTimedays).padStart(2, "0");
        console.log(day, h, m, s)
        // return { day, h, m, s }
        let Day_To_Number = Number(day)
        let Hour_To_Number = Number(h)
        let Minute_To_Number = Number(m)
        let Second_To_Number = Number(s)
        console.log(Day_To_Number, Hour_To_Number, Minute_To_Number, Second_To_Number)


        if (Day_To_Number <= 0 && Hour_To_Number <= 0 && Minute_To_Number <= 0 && Second_To_Number <= 0) {

            setIsBetweenLeaguge(false)
            let finaltime = AppSetting[0].AppSetting.timeFinishLeague - curTime


            const seconds = Math.floor(finaltime / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const remainingSeconds = seconds % 60;
            const remainingMinutes = minutes % 60;
            const remainingHours = hours % 24;
            let m, h, s, day;
            m = String(remainingMinutes).padStart(2, "0");
            h = String(remainingHours).padStart(2, "0");
            s = String(remainingSeconds).padStart(2, "0");
            day = String(days).padStart(2, "0");

            setTime({
                days: day,
                hours: h,
                minutes: m,
                seconds: s,
            })
        } else {
            setMyLeague(false)
            setIsBetweenLeaguge(true)

            setTime({
                days: day,
                hours: h,
                minutes: m,
                seconds: s,
            })
        }

    }
    const intervalRef1 = useRef(true);
    useEffect(() => {
        console.log(intervalRef1)
        if (intervalRef1.current != null) {
            intervalRef1.current = setInterval(() => {
                TimerAppSetting()
                clearInterval(intervalRef1.current);
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef1.current);
        };
    }, [intervalRef1]);



    return (

        !LoadState ?
            <div className='GameCoin'>
                <Link to="/Game" className='GameCoinContainer'>

                    <InnerGame />
                </Link>
                <Promotion />
                <Congratulations />
                <Congratulations2 />
                <Congratulations3 />
            </div>
            :       <Skeleton variant="rounded"  className="SkeletonInCoinGame" width={210} height={60} />



    )
}

export default GameCoin