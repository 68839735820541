import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './Slice'
import HomeReducer from './Home'
import JoinState from './JoinState'
import Game from './Game'
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    Home:HomeReducer,
    JoinState:JoinState,
    Game:Game
  },
})