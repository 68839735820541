import React from 'react'
import { useState, useEffect } from 'react';
// import './ChaeckVerificationCode.css'
import BackImg from './../Public/Image/Login/Back.svg'
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
// import { CheckVerificationCodeApi } from './../Api/CheckVerificationCodeApi'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { SendVerificationCodeApi } from './../Api/SendVerificationCodeApi'
import {GetCookie} from './../Action/GetCookie'
import {SetCookie} from './../Action/SetCookie'
import { useSelector, useDispatch } from 'react-redux'
import { setMiniLoading } from './../app/Home'
import { db } from './../DataBase/db'
import { AllApi } from './../Api/AllApi'

const AddNumberVerify = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const PhoneNumber = searchParams.get('PhoneNumber');
    const Timeurl = searchParams.get('Time');
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const dispatch = useDispatch()

    const [Code, setCode] = useState('')
    const CodeHandler = (e) => {
        setErrorCode(false)
        setErrorwaitForResendCode(false)
        setCode(e.target.value)
    }
    const [ErrorCode, setErrorCode] = useState(false)
    const CheckVerificationCodeFunc = async () => {
        let Token = GetCookie('Token')
        dispatch(setMiniLoading(true))

        if (Code.length == 0) {
            setErrorCode(true)
        } else {
            // let responseverify = await CheckVerificationCodeApi(Token, Code)
            const MakeApiClass = new AllApi();
            let responseverify=await MakeApiClass.CheckVerificationCodeApi(Token, Code)
            // console.log(responseverify)
            if (responseverify.code === 200) {
                // activate acount
                // clearInterval(timer);
                SetCookie('active','true',100)
                addUserDb(PhoneNumber)

                history.push(`/Profile`);

            } else if (responseverify.code === 301) {
                // code  300 : کد وارد شده اشتباه است
                setErrorCode(true)
            } else if (responseverify.code === 302) {
                // code  300 :  بازه زمانی وارد کردن کد به پایان رسیده است
            } else if (responseverify.code === 300) {
                // Invalid value
            } else if (responseverify.code == 120 || responseverify.code == 121 || responseverify.code == 122 || responseverify.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))

            }
        }

        dispatch(setMiniLoading(false))

    }
    // addUserDb()
    async function addUserDb() {
        try {
            // const id = await db.Data.add({
            //     PhoneNumber
            // });
            let resdata = await db.Data.toArray()
            // console.log(resdata)
            let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ PhoneNumber: PhoneNumber })


        } catch (error) {
            // console.log(`Failed to add ${PhoneNumber}: ${error}`);
        }
    }
    const [Minutes, setMinutes] = useState('')
    const [Seconds, setSeconds] = useState('')


    function formatTime(seconds) {
        // console.log(seconds)
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        // return `${formattedMinutes}:${formattedSeconds}`;
        setMinutes(formattedMinutes)
        setSeconds(formattedSeconds)
    }

    const [time, setTime] = useState('');
    const CalcTime = (time1) => {
        let LocalTimeUrl = time1
        // console.log(LocalTimeUrl)
        const timer = setInterval(() => {
            const now = new Date();
            const currentTimeInMilliseconds = now.getTime();
            const timeDifference = Math.abs(LocalTimeUrl - currentTimeInMilliseconds);
            let secondsDifference = timeDifference / 1000;
            secondsDifference = Math.round(secondsDifference)


            // console.log(secondsDifference)

            if (LocalTimeUrl > currentTimeInMilliseconds) {
                setTime(secondsDifference)
                // console.log(secondsDifference)
                formatTime(secondsDifference)
            } else {
                clearInterval(timer);
                setTime(-1)
            }

        }, 1000);

    }
    useEffect(() => {
        CalcTime(Timeurl)

    }, []);
    const [ErrorwaitForResendCode, setErrorwaitForResendCode] = useState(false)
    const [waitForResendCode, setwaitForResendCode] = useState(0)
    const ResendCodeFunc = async () => {
        let Token = GetCookie('Token')
        // console.log(CountryCode + PhoneNumber)
        // let FullPhoneNumber = CountryCode + PhoneNumber
        // let responseverify = await SendVerificationCodeApi(Token, PhoneNumber)
            const MakeApiClass = new AllApi();
            let responseverify=await MakeApiClass.SendVerificationCodeApi(Token, PhoneNumber)
        // console.log(responseverify)
        const now = new Date();
        let currentTimeInMilliseconds = now.getTime();
        // console.log(currentTimeInMilliseconds)
        const Limitmilliseconds = responseverify.data.waitForResendCode * 1000;
        // console.log(Limitmilliseconds)
        let FinalTime = currentTimeInMilliseconds + Limitmilliseconds
        CalcTime(FinalTime)

        if (responseverify.code === 201) {
            // code send

            // history.push(`/CheckVerificationCode?PhoneNumber=${FullPhoneNumber}&&Time=${responseverify.data.waitForResendCode}`);

        } else if (responseverify.code === 300) {
            // code  300 : Invalid value
        } else if (responseverify.code === 200) {
            // code  300 : not iranian phone
        } else if (responseverify.code === 301) {
            // waitForResendCode
            setErrorwaitForResendCode(true)
            setwaitForResendCode(responseverify.data.waitForResendCode)
        } else if (responseverify.code == 120 || responseverify.code == 121 || responseverify.code == 122 || responseverify.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }
    }
    return (
        <div className='GetPhoneNumberContainer'>
            <div className='GetPhoneNumberHeader'>
                <Link to="/GetPhoneNumber" className='GetPhoneNumberBackIcon'>
                    <img src={BackImg} />
                </Link>
                <div className='GetPhoneNumberHeaderText'>
                    Verification code
                </div>
            </div>
            <div className='GetPhoneNumberBody'>


                <div className='GetPhoneNumberBodyText'>
                    Please enter verification code sent to {PhoneNumber}
                </div>
                <div className='GetPhoneNumberInput'>

                    {ErrorCode ?
                        <TextField
                            error
                            id="outlined-error-helper-text"
                            label="Verification code"
                            defaultValue="Hello World"
                            helperText={`code is incorrect`}
                            value={Code} onChange={(e) => CodeHandler(e)} className='GetCodePhoneTextField' type='number' />
                        :
                        ErrorwaitForResendCode ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                label="Verification code"
                                defaultValue="Hello World"
                                helperText={`waitForResendCode ${waitForResendCode}`}
                                value={Code} onChange={(e) => CodeHandler(e)} className='GetCodePhoneTextField' type='number' />
                            :
                            <TextField value={Code} onChange={(e) => CodeHandler(e)} label="Verification code" className='GetCodePhoneTextField' type='number' />}


                </div>



            </div>
            <div className='GetPhoneNumberFooter'>
                <div className='GetPhoneNumberTimer'>
                    {time >= 0 ?
                        Minutes + ':' + Seconds + ' remaining until resend'
                        :
                        <div className='ResendCode' onClick={() => ResendCodeFunc()}>
                            Resend Code
                        </div>
                    }

                </div>
                <div className='GetPhoneNumberLinkDiv'>
                    <button className="GetPhoneNumberLink"
                        onClick={() => CheckVerificationCodeFunc()}
                    >
                          {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Confirm'
                        }
                        
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddNumberVerify