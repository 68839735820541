import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './TooManyOrders.css'
import { setBottomSheetTooManyOrdersUser } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
const TooManyOrdersUser = () => {
    const dispatch = useDispatch()
    const BottomSheetTooManyOrdersUser = useSelector((state) => state.Home.BottomSheetTooManyOrdersUser)
    const BottomSheetTooManyOrdersUserTitle = useSelector((state) => state.Home.BottomSheetTooManyOrdersUserTitle)
    const BottomSheetTooManyOrdersUserDescription = useSelector((state) => state.Home.BottomSheetTooManyOrdersUserDescription)
    
    
    const onDismiss = () => {
        setTimeout(() => {
        dispatch(setBottomSheetTooManyOrdersUser(false))
    }, 300);
    }
    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetTooManyOrdersUser}>
            <div className='TooManyOrders'>
                <div className='TooManyOrdersTitle'>
                    {/* Too many orders in progress */}
                    {BottomSheetTooManyOrdersUserTitle}
                </div>
                <div className='TooManyOrdersDes'>
                    {/* This channel has 1 order in progress. Please re-order when done */}
                    {BottomSheetTooManyOrdersUserDescription}
                </div>
                <div className='TooManyOrdersFooter'>
                    <button className='TooManyOrdersButton' onClick={() => dispatch(setBottomSheetTooManyOrdersUser(false))}>
                        Got it
                    </button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default TooManyOrdersUser