import React from 'react'
import './Gift.css'
import GiftIcon from './../Public/Image/Gift/Gift.svg'
import Arrow from './../Public/Image/Gift/Arrow.svg'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import { useState } from 'react';
import TextField from '@mui/material/TextField';
// import { GiftApi } from './../CoinApi/GiftApi'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from './../Action/GetCookie'
import { BottomSheet } from 'react-spring-bottom-sheet'
import VerifiedIcon from '@mui/icons-material/Verified';

const GiftCode = () => {
  const history = useHistory();
  let Token = GetCookie('Token');
  const [BottomShSuccess, setBottomShSuccess] = useState(false)
  function onDismiss() {
    setTimeout(() => {
      setBottomShSuccess(false)
    }, 300);
  }
  const [GifTCodeState, setGifTCodeState] = useState('')
  const [Error, setError] = useState({ status: false, message: '' })
  const [Success, setSuccess] = useState({ status: false, message: '' })
  const SetCode = (e) => {
    setGifTCodeState(e.target.value)
    setError({ status: false, message: '' })
  }
  const CheckApi = async () => {
    // let res = await GiftApi(Token, GifTCodeState)
    const MakeApiClass = new AllApi();
    let res=await MakeApiClass.GiftApi(Token, GifTCodeState)
    // console.log(res)
    if (res.code == 301 || res.code == 302) {
      setError({ status: true, message: res.message })
      // setBottomShSuccess(true)


    } else if (res.code == 200) {
      // alert(res.message)
      setBottomShSuccess(true)
      setSuccess({ status: true, message: res.message })
    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

  }
  }
  // console.log(GifTCodeState.length)
  return (
    <div className='GiftCodeContainer'>
      <div className='TransferHistoryHeader'>
        <Link to="/Coin?Status=Gift">
          <img src={Back} className='TransferHistoryHeaderImg' />
        </Link>
        <div className='TransferHistoryHeaderText'>Gift code</div>
      </div>


      <div className='GiftCodeBody'>
        <div className='GiftCodeInput'>
          <div className='GiftCodeBodyText'>
            Please enter the gift code received from the channel
          </div>
          <div className='TextFieldGiftCode'>
            {
              Error.status ?
                <TextField
                  className="TransferDestinationInput"
                  error
                  id="outlined-error-helper-text"
                  label="gift code"
                  defaultValue={GifTCodeState}
                  helperText={Error.message}
                  onChange={(e) => SetCode(e)}
                  value={GifTCodeState}
                />
                : <TextField id="outlined-basic"
                  label="gift code"
                  className="TransferCoinCountInput"
                  variant="outlined"
                  onChange={(e) => SetCode(e)}
                  value={GifTCodeState}
                  type="text"
                />
            }


          </div>
        </div>
        <BottomSheet onDismiss={onDismiss} open={BottomShSuccess}>
          <div className='RegisterBeforeBottomSheet'>
            <div className='RegisterBeforeBottomSheetHeader'>
              <div className='SuccessBBottomSheetHeaderText'>
                <VerifiedIcon style={{ "color": "#2E7D32", "marginRight": "8px" }} />
                Congratulations</div>
            </div>
            <div className='RegisterBeforeBottomSheetBody'>
              {/* {TargetCoin}  coin transferred to '{TargetEmail}' */}
              {/* 200 coins have been added to your account as a gift */}
              {Success.message}

            </div>
            <div className='RegisterBeforeBottomSheetFooter'>
              {/* <button className='BottomSheetCancelButton' onClick={() => SuccessB(false)}>Cancel</button> */}
              <button className='BottomSheetSuccessB' onClick={() => setBottomShSuccess(false)}>Got it</button>
            </div>
          </div>
        </BottomSheet>
        <div className='ButtonRecordContainer'>
          {(Error.status) || (GifTCodeState.length == 0) ?
            <button className='GiftCodeRecordDisable'>Register</button>
            :
            <button className='GiftCodeRecord' onClick={() => CheckApi()}>Register</button>
          }


        </div>

      </div>
    </div>
  )
}

export default GiftCode