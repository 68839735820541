import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import One from './../../Public/Image/Game/topjoiner/icons8_1st_place_medal_1 1.svg'
import Two from './../../Public/Image/Game/topjoiner/icons8_2nd_place_medal 1.svg'
import Three from './../../Public/Image/Game/topjoiner/icons8_3rd_place_medal 1.svg'
import {SetCongratulationsBottomSheet3} from './../../app/Game'
import './Congratulations3.css'
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import { useSelector, useDispatch } from 'react-redux'
import Animation from './../../Public/Animation/3.json'
import Lottie from "lottie-react";
const Congratulations3  = () => {
    const dispatch = useDispatch()
    const CongratulationsBottomSheet3 = useSelector((state) => state.Game.CongratulationsBottomSheet3)
    const GiftTopjoin = useSelector((state) => state.Game.GiftTopjoin)

    function onDismiss() {
        setTimeout(() => {
            dispatch(SetCongratulationsBottomSheet3(false))
        }, 300);
        
    } 
    function abbreviate_number(num, fixed) {
        
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
        return e;
    }
    const CloseBottomSheet=()=>{

        setTimeout(() => {
              dispatch(SetCongratulationsBottomSheet3(false))
          }, 300);
          let curTime = Date.now();
  
          localStorage.setItem('CloseCongratulations',curTime)
  }
  useEffect(() => {
    const timer = setInterval(async () => {
       
        // console.log(GiftTopjoin)
        if (GiftTopjoin > 0) {
           
            clearInterval(timer);
            
        } else {
            // console.log(147852)
        }

    }, 1000);
}, [GiftTopjoin])
    return ( 
        GiftTopjoin[0] ?
        <BottomSheet onDismiss={onDismiss} 
        open={CongratulationsBottomSheet3}
        // open={true}
        >
            <div className='CongratulationsBottomSheet'>
                <div className='CongratulationsBottomSheetHeader'>
                    <div className='CongratulationsBottomSheetHeaderText'>🎉 Congratulations 🎉</div>
                </div>
                <div className='CongratulationsBottomSheetBody'>

            <Lottie animationData={Animation} loop={false} style={{ "width": "120px", "height": "120px" }}/>

             <div className='CongratulationsBottomSheetBodyReward'>
             Your Rewards:
             </div>
             <div className='CongratulationsBottomSheetBodyRewardNumber'>
             {GiftTopjoin[2].coin?abbreviate_number(GiftTopjoin[2].coin):0} <img src={Coin} className='CongratulationsBottomSheetBodyRewardCoin'/> + {GiftTopjoin[2].xp?abbreviate_number(GiftTopjoin[2].xp):0} <img src={Star} className='CongratulationsBottomSheetBodyRewardStar'/>
             </div>
                </div> 
                <div className='CongratulationsBottomSheetFooter'>
                 
                    <button  className='CongratulationsBottomSheetButton' onClick={()=> CloseBottomSheet()} >Confirm</button>
                </div>
            </div>
        </BottomSheet>  : null
    )
}

export default Congratulations3