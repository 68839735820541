import React from 'react'
import Add from './../Public/Image/Join/Add.svg'
import rafiki from './../Public/Image/Join/rafiki.svg'
import './JoinAddAccount.css'
import { Link } from 'react-router-dom';

const AddAccount = () => {
  return (
    <div className='AddAccountContainer'>
                    <div>
                        <img alt='rafiki'  src={rafiki} />
                    </div>
                    <div className='AddAccountTitle'>
                        Get free coins
                    </div>
                    <div className='AddAccountDes'>
                        <div className='AddAccountDesSteps'>
                            <div className='AddAccountDesStepsItem'>1</div>
                            <div className='AddAccountDesStepsItemLine'></div>
                            <div className='AddAccountDesStepsItem'>2</div>
                        </div>
                        <div className='AddAccountDesText'>
                            <div className='AddAccountDesTextItem1'>
                                Add Telegram account
                            </div>
                            <div className='AddAccountDesTextItem2'>
                                {/* After addition, account auto-joins to channel/groups and coins are auto-added to this account */}
                                After that, join channels/groups and coins will be automatically added to your main account 
                            </div>
                        </div>
                    </div>
                    <div className='AddAccountWarning'>
                        ⚠️ <strong >Warning</strong>: Please avoid adding your primary Telegram account and use Virtual number
                    </div>
                    
                    <div className='AddAccountButtonContainer'>
                        <Link to="LoginTelegram" className='AddAccountButton' >
                            <img alt='add'  src={Add} className='AddAccountButtonImg'/>    Add account
                        </Link>
                    </div>

                </div>
  )
}

export default AddAccount