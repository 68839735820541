import React from 'react'
export function SetCookie(name,value,days,min) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    
    }
    // else{
    //     var date = new Date();
    //     date.setTime(date.getTime() + (min*60*1000));
    //     expires = "; expires=" + date.toUTCString();  
    // }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}