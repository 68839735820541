import React, { useEffect, useState } from 'react'
import './Transfer.css'
// import TextField from '@mui/material/TextField';
// import RestoreIcon from '@mui/icons-material/Restore';
// import { useLiveQuery } from "dexie-react-hooks";
// import { db } from './../DataBase/db'
// import { useEffect } from 'react';
import { SetEmail } from './../app/Slice'
import { useSelector, useDispatch } from 'react-redux'
// import { TransferCoinApiHistory } from './../CoinApi/TransferCoinApiHistory'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';

import { GetCookie } from './../Action/GetCookie'
import Back from './../Public/Image/BuyCoin/Back.svg'
import HistoryEmpty from './../Public/Image/HistoryEmpty.svg'

import { useLiveQuery } from "dexie-react-hooks";
import { db } from './../DataBase/db'
import TransferHistoryItemSend from './TransferHistoryItemSend'
import TransferHistoryItemRecive from './TransferHistoryItemRecive'
import LoadingBig from './../Loading'

import { Link } from 'react-router-dom';

const TransferHistory = () => {
  const dispatch = useDispatch()
  const Email = useSelector((state) => state.counter.email)
  const Data = useLiveQuery(
    () => db.Data.toArray()
  );
  useEffect(() => {

    if (Data) {
      //  console.log(Data) 
     
      dispatch(SetEmail( Data[0].Email))
      // Data.map(item => {
      //   if (item.Email) { dispatch(SetEmail(item.Email)) }
      // })

    }

  }, [Data])
  const history = useHistory();

  let Token = GetCookie('Token');
  const [DataHistory, setDataHistory] = useState('')
  const [LoadingState, setLoadingState] = useState(false)
  const GetHistory = async () => {
    setLoadingState(true)
    // let res = await TransferCoinApiHistory(Token)
    const MakeApiClass = new AllApi();
    let res=await MakeApiClass.TransferCoinApiHistory(Token)
    // console.log(res)

    if (res.code == 200) {
      setDataHistory(res.data.data.docs)
    }else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

  }
    setLoadingState(false)

  }
  useEffect(() => {
    GetHistory()
  }, [])

  // console.log(DataHistory)
  // console.log(Email , DataHistory)
  return (
    <div className='TransferHistoryContainer'>
      <div className='TransferHistoryHeader'>
        <Link to="/Coin?Status=Transfer">
          <img src={Back} className='TransferHistoryHeaderImg' />
        </Link>
        <div className='TransferHistoryHeaderText'>Transfer history</div>
      </div>
      <div className='TransferHistoryBody'>
        {
          LoadingState ?
            <LoadingBig />
            :
            DataHistory.length > 0 ?
              DataHistory.map((item, i) =>
                Email == item.reciever_email ?
                  <TransferHistoryItemRecive item={item} key={i} />
                  :
                  <TransferHistoryItemSend item={item} key={i} />
              )
              :
              <div className='TransferHistoryEmpty'>
                <img src={HistoryEmpty} />
              </div>



        }



      </div>

    </div>
  )
}

export default TransferHistory