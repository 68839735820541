// import React from 'react'
import { TelegramDB } from './../DataBase/TelegramDB'
import { useLiveQuery } from "dexie-react-hooks";

export async function RequestToTelegramDb() {
    let Datares = useLiveQuery(
        () => TelegramDB.Data.toArray()
    );

    if (Datares) {
        return Datares
    }
}

