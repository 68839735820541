import React from 'react'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import { AllApi } from './../Api/AllApi'
import { useState, useEffect } from 'react';
import { db } from './../DataBase/db'
import { useLiveQuery } from "dexie-react-hooks";
import { GetCookie } from './../Action/GetCookie'
import { useSelector, useDispatch } from 'react-redux'
import { SetEmail } from './../app/Slice'
import { SetNameAccount } from './../app/Slice'
import { SetPicture } from './../app/Slice'
import { phone } from 'phone';
import { useHistory } from 'react-router-dom';
import Loading from './../Loading'
const EditProfile = () => {
  const Token = GetCookie('Token');
  const dispatch = useDispatch()
  const Email = useSelector((state) => state.counter.email)
  const NameAccount = useSelector((state) => state.counter.NameAccount)
  const Picture = useSelector((state) => state.counter.Picture)
  const [PhoneNumber, setPhoneNumber] = useState(null)
  const history = useHistory();
  const [LoadState, setLoadState] = useState(false)
  const GetProfileFunction = async () => {
    setLoadState(true)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetProfile(Token)
    // console.log(res)
    if (res.code == 200) {
      dispatch(SetEmail(res.data.user.email))
      dispatch(SetNameAccount(res.data.user.nameAccount))

      if (res.data.user.phonenumber) {

        setPhoneNumber(phone(res.data.user.phonenumber))
      } else {


        setPhoneNumber(false)

      }


      dispatch(SetEmail(res.data.user.email))
      let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })


    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");


    }
    setLoadState(false)

  }

  useEffect(() => {
    GetProfileFunction()
  }, [])



  const [SetPasswordBool, setSetPasswordBool] = useState(null)
  const GetProfileFunction1 = async () => {
    // let res = await GetProfile(Token)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetProfile(Token)
    console.log(res)
    if (res.code == 200) {


      if (res.data.user.setPassword == true) {
        // console.log(res.data.user.phonenumber)
        setSetPasswordBool(true)

      } else {
        // console.log(res.data.user.phonenumber)
        setSetPasswordBool(false)



      }


    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    }

  }

  useEffect(() => {
    GetProfileFunction1()
  }, [])
  // const GetSetPassword = () => {
  //   history.push("/SetPassword");
  // }
  // const GetChangePassword = () => {
  //   history.push("/GetCheckPassword");
  // }
  console.log(PhoneNumber)
  return (
    LoadState || SetPasswordBool == null ?
      <Loading />
      :

      <div className='AccountInformationContainer'>
        <div className='TransferHistoryHeader'>
          <Link to="/Profile">
            <img src={Back} className='TransferHistoryHeaderImg' />
          </Link>
          <div className='TransferHistoryHeaderText'>Edit Profile</div>
        </div>
        <div className='AccountInformationBody'>
          <div className='AccountInformationItem' style={{'marginBottom':'8px'}}>
            <div className='AccInfoTitleEditProfile'><EmailOutlinedIcon className='EditProfileIcons' /> Email</div>
            <div className='AccInfoValueEditProfile' style={{ 'color': '#212121' }}>{Email}</div>
          </div>
          {PhoneNumber !=null && PhoneNumber != false ?
           <Link to={`/EditNumber`} className='AccountInformationItem' style={{'marginBottom':'8px'}}>
            <div className='AccInfoTitleEditProfile'><SmartphoneOutlinedIcon className='EditProfileIcons' />Phone number</div>
            <div className='AccInfoValueEditProfile'> {PhoneNumber ? PhoneNumber.phoneNumber : null}</div>
          </Link>
          :
          null
          }
         
          <Link to={`/SetName`} className='AccountInformationItem' style={{'marginBottom':'8px'}}>
            <div className='AccInfoTitleEditProfile'><PersonOutlineOutlinedIcon className='EditProfileIcons' />Name</div>
            <div className='AccInfoValueEditProfile'>{NameAccount}</div>
          </Link>

          {SetPasswordBool == false ?
            <Link to={`/SetPassword`} className='AccountInformationItem' style={{'marginBottom':'8px'}}>
              <div className='AccInfoTitleEditProfile'><PasswordIcon className='EditProfileIcons' />Password</div>
              <div className='AccInfoValueEditProfile'>Set</div>
            </Link>

            :
            <Link to={`/GetCheckPassword`} className='AccountInformationItem' style={{'marginBottom':'8px'}}>
              <div className='AccInfoTitleEditProfile'><PasswordIcon className='EditProfileIcons' />Password</div>
              <div className='AccInfoValueEditProfile'>Change</div>
            </Link>
          }



        </div>
      </div>
  )
}

export default EditProfile