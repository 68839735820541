import React from 'react'
import { useState, useEffect } from 'react'
import MemberTabActive from './../Public/Image/Home/MemberTabActive.svg'
import MemberTabDEActive from './../Public/Image/Home/MemberTabDEActive.svg'
import ViewTabDisable from './../Public/Image/Home/ViewTabDisable.svg'
import ViewTabEnable from './../Public/Image/Home/ViewTabEnable.svg'
import { useSelector, useDispatch } from 'react-redux'
import { SetTab } from './../app/Home'
import { useLocation } from 'react-router-dom';

const HomeTabs = () => {
    const dispatch = useDispatch()
    const Tab = useSelector((state) => state.Home.Tab)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const Status = searchParams.get('Status');
    const Filter = searchParams.get('Filter');

    useEffect(() => {
       
        if (Status === 'View') {
            SetTabsStatusView()
        } else {
            SetTabsStatusMember()
        }
    }, [])

    const SetTabsStatusMember = () => {
        dispatch(SetTab('Member'))
        let currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('Status', 'Member');
        window.history.replaceState({}, document.title, currentUrl.href);
    }
    const SetTabsStatusView = () => {
        dispatch(SetTab('View'))
        let currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('Status', 'View');
        window.history.replaceState({}, document.title, currentUrl.href);
    }
   
    return (
        <div className='TabHomeContainer'>
            <div className='TabsHomeHeader'>
                <div className='TabsHomeHeaderItem' onClick={() => SetTabsStatusMember()}>
                    <span className={Tab == 'Member' ? 'TabHomeSpanActive' : 'TabHomeSpanDisable'} >
                        <span className='TabHomeTextImg'>
                            {Tab == 'Member' ?
                                <img src={MemberTabActive} className='TabHomeTextImgIcon' />
                                : <img src={MemberTabDEActive} className='TabHomeTextImgIcon' />
                            }
                            Member
                        </span>

                        {Tab == 'Member' ?
                            <div className='TabHomeActivateBorder'></div>
                            :
                            <div className='TabHomeDeActivateBorder'></div>}
                    </span>

                </div>
                <div className='TabsHomeHeaderItem' onClick={() => SetTabsStatusView()}>
                    <span className={Tab == 'View' ? 'TabHomeSpanActive' : 'TabHomeSpanDisable'} >
                        <span className='TabHomeTextImg'>
                            {Tab == 'View' ?
                                <img src={ViewTabEnable} className='TabHomeTextImgIcon' />
                                :
                                <img src={ViewTabDisable} className='TabHomeTextImgIcon' />

                            }



                            View
                        </span>

                        {Tab == 'View' ? <div className='TabHomeActivateBorder'></div> : <div className='TabHomeDeActivateBorder'></div>}
                    </span>

                </div>
            </div>
        </div>

    )
}

export default HomeTabs