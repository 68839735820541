import React from 'react'
import './ViewPackItem.css'
import ViewIcon from './../Public/Image/Home/ViewIcon.svg'
import Coin from './../Public/Image/Home/Coin.svg'
import { GetView } from './../Api/GetView'
import { GetCookie } from '../Action/GetCookie'
import { useEffect } from 'react'
import { setViewPackageData } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { AllApi } from './../Api/AllApi'

const ViewPackItemCoin = () => {
    const Token = GetCookie('Token')
    const ViewPackageData = useSelector((state) => state.Home.ViewPackageData)
    const PostViewFilter = useSelector((state) => state.Home.PostViewFilter)
    const dispatch = useDispatch()
    const history = useHistory();


    // const GetViewFunc = async () => {
    //     // let res = await GetView(Token)
    //     const MakeApiClass = new AllApi();
    //     let res = await MakeApiClass.GetView()
    //     // console.log(res)
    //     if (res.code == 200) {
    //         dispatch(setViewPackageData(res.data.data))

    //     }else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
    //         let name = "Token"
    //         let name1 = "active"
    //         document.cookie = name + '=; Max-Age=-99999999;';
    //         document.cookie = name1 + '=; Max-Age=-99999999;';
    //         history.push("/");

    //     }

    // }
    useEffect(() => {
// console.log('set view coin')
        // if (ViewPackageData.length === 0) {
            // GetViewFunc()
        // }

    }, [])
    const CheckViewPackageItem = (price, count,sku,_id) => {
        // console.log(price, count)
        history.push(`/GetUsernameView?price=${price}&count=${count}&ByCoin=true&PostViewFilter=${PostViewFilter}&sku=${sku}&_id=${_id}`);
        // coin
    }
    return (
        <>
            {
                ViewPackageData.length > 0 ?
                    ViewPackageData.map((ViewItem, i) =>
                        ViewItem.count_post == PostViewFilter ?
                            ViewItem.coin.map((item, j) =>
                                <div className='ViewPackageItem' key={j} onClick={() => CheckViewPackageItem(item.coin, item.view_count,item.sku,item._id)}>
                                    <div className='ViewItem'>
                                        <div>
                                            <img src={ViewIcon} />
                                        </div>
                                        <div className='ViewItemDigit'>
                                            <div className='ViewItemDigitNewPrice'>

                                                {item.view_count.toLocaleString()}
                                            </div>
                                            {/* <div className='ViewItemDigitOldPrice'>
                                            {item.view_count_real}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='ViewItem'>

                                        {/* <div className='ViewItemOffDigit'>
                                            25% OFF
                                        </div> */}
                                        <div >
                                            <div className='ViewItemOrder'>
                                                <button className='ViewItemOrderButton'> <img className='CoinIconButton' src={Coin} />{item.coin.toLocaleString()}</button>
                                            </div>
                                            {/* <div className='ViewItemOrderOldPrice'>
                                                10.900 $
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            )

                            : null
                    )
                    : null
            }
        </>


    )
}

export default ViewPackItemCoin