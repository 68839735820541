import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { setBottomSheetPriceAndChannel } from './../app/Home'

import { setBottomSheetCheckUsername } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import Person from './../Public/Image/Home/Person.svg'
import PersonIcon from '@mui/icons-material/Person';
// import { Purchasememberpaypal } from './../Api/Purchasememberpaypal'
import { useLocation } from 'react-router-dom';
import cryptlib from "cryptlib"
import { setMiniLoading } from './../app/Home'
import { setIsIranian } from './../app/Home'
import { AllApi } from './../Api/AllApi'
import { GetCookie } from './../Action/GetCookie'
import { setUserFromApi } from './../app/Home'
import { SetEmail } from './../app/Slice'

const ShowPriceAndChannel = () => {
    const dispatch = useDispatch()
    const BottomSheetPriceAndChannel = useSelector((state) => state.Home.BottomSheetPriceAndChannel)
    const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)
    const location = useLocation();
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const UserFromApi = useSelector((state) => state.Home.UserFromApi)

    const searchParams = new URLSearchParams(location.search);

    const _id = searchParams.get('_id');
    const sku = searchParams.get('sku');
    const price = searchParams.get('Price');
    const Count = parseInt(searchParams.get('Count'));
    const Email = useSelector((state) => state.counter.email)
    const IsIranian = useSelector((state) => state.Home.IsIranian)
    // console.log(IsIranian)
    const Token = GetCookie('Token')

    const GetProfile = async () => {
        const MakeApiClass = new AllApi();
        let ProfileRes = await MakeApiClass.GetProfile(Token)
        // console.log(ProfileRes)
        let UserPhone
        if (ProfileRes.code == 200) {
            dispatch(setUserFromApi(ProfileRes.data.user))
            if (ProfileRes.data.user) {
                // let IsIraNianForApi=false
                dispatch(SetEmail(ProfileRes.data.user.email))
                UserPhone = ProfileRes.data.user.phonenumber;
                if (UserPhone.length > 0 && UserPhone != undefined && UserPhone != null) {
                    UserPhone.startsWith("+98") ? dispatch(setIsIranian(true)) : dispatch(setIsIranian(false))
                    // UserPhone.startsWith("+98")?IsIraNianForApi=true:IsIraNianForApi=false

                }
            }
        }
        return UserPhone
    }

    function onDismiss() {
        setTimeout(() => {
            dispatch(setBottomSheetPriceAndChannel(false))
        }, 300);
    }
    const Pay = async () => {
        dispatch(setMiniLoading(true))

        // let res = await Purchasememberpaypal(Email, _id, sku, price)
        // console.log(IsIranian)

        if (IsIranian == false) {
            window.location.href = `https://api.membersgram.com/main/v1/webapp/purchasememberpaypal?data=${data}`
        } else if (IsIranian == true) {
            window.location.href = `https://api.membersgram.com/main/v1/webapp/zarinpal/member/gateway?data=${data}`

        }



        dispatch(setMiniLoading(false))

    }
    useEffect(() => {
        if (IsIranian == undefined) {
              GetProfile()

        }
    }, [])

    // console.log(Email, _id, sku, price)
    const iv = '#GV^%^SUR&T*#Y*4'
    const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
    const user = {
        "email": Email,
        "_id": _id, // بسته _id
        "sku": sku,
        "price": price,
        "username": UserNameForOrder,
        "phonenumber": UserFromApi.phonenumber
    }
    // console.log(user)
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);
    // console.log(IsIranian)

    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetPriceAndChannel}>
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Price</div>
                        <div className='ShowPriceAndChannelValue'>{price} {IsIranian ? "T":<span className='DollarSign'>$</span>}</div>
                    </div>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Count</div>
                        <div className='ShowPriceAndChannelValue'>{Count.toLocaleString()} <PersonIcon className='PersonIcon' /></div>
                    </div>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Username</div>
                        <div className='ShowPriceAndChannelValue'>{UserNameForOrder}</div>
                    </div>
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    {/* <a href={`https://api.membersgram.com/main/v1/webapp/purchasememberpaypal?data=${data}`}>
                        123
                    </a> */}
                    {/* <button className='BottomSheetCancelButton' onClick={() => dispatch(setBottomSheetCheckUsername(false))}>Cancel</button> */}
                    <button className='BottomSheetShowPriceAndChannelButton' onClick={() => Pay(data)}>
                        {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Pay'
                        }

                    </button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default ShowPriceAndChannel