import React from 'react'
import PaymentErrorIcon from './../Public/Image/Home/PaymentError.svg'
import './PaymentError.css'
const PaymentError = () => {
    return (
        <div className='PaymentErrorContainer'>
            <div className='PaymentError'>
                <div className='PaymentErrorIcon'>
                    <img src={PaymentErrorIcon} />
                </div>
                <div className='PaymentErrorText'>
                    <div className='PaymentErrorTitle'>Payment error</div>
                    <div className='PaymentErrorDes'>
                        To fix the problem, please click the Retry button if the problem persistsT contact support
                    </div>
                    <div className='PaymentErrorToken'>
                        250 coin purchase token: <span>12Ssd65s47</span>
                    </div>
                    
                    <div className='PaymentErrorButtons'>
                        <button className='PaymentErrorSupport'>Contact support</button>
                        <button className='PaymentErrorRetry'>Retry</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PaymentError