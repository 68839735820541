import React from 'react'
import './Transfer.css'
import TextField from '@mui/material/TextField';
import RestoreIcon from '@mui/icons-material/Restore';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from './../DataBase/db'
import { useEffect } from 'react';
import { SetEmail } from './../app/Slice'
import { useSelector, useDispatch } from 'react-redux'
// import { TransferCoinApi } from './../CoinApi/TransferCoinApi'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from './../Action/GetCookie'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import VerifiedIcon from '@mui/icons-material/Verified';
import { setUserCoin } from './../app/Home'
import { useHistory } from 'react-router-dom';

const Transfer = () => {
  let Token = GetCookie('Token');
  const history = useHistory();

  const dispatch = useDispatch()
  const Email = useSelector((state) => state.counter.email)
  const Data = useLiveQuery(
    () => db.Data.toArray()
  );
  useEffect(() => {

    if (Data) {
      //  console.log(Data) 
      Data.map(item => {
        if (item.Email) { dispatch(SetEmail(item.Email)) }
      })

    }

  }, [Data])
  const [TargetEmail, setTargetEmail] = useState('')
  const [TargetCoin, setTargetCoin] = useState('')
  const [EmailError, setEmailError] = useState({ status: false, message: '' })
  const [CoinError, setCoinError] = useState({ status: false, message: '' })
  const [BottomShSuccess, setBottomShSuccess] = useState(false)
  function onDismiss() {
    setBottomShSuccess(false)
  }
  const setDestEmail = (e) => {
    setEmailError(false)
    setTargetEmail(e.target.value)
  }
  const setDestCoin = (e) => {
    setCoinError({ status: false, message: "" })
    setTargetCoin(e.target.value)
  }
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const TransferFunction = async () => {
    setCoinError({ status: false, message: "length" })
if(TargetEmail.length>0){
    if (isValidEmail(TargetEmail)) {
      if (TargetCoin.length > 0) {
        // let res = await TransferCoinApi(Token, TargetEmail, TargetCoin)
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.TransferCoinApi(Token, TargetEmail, TargetCoin)
        // console.log(res)
        if (res.code == 302) {
          res.messageErrors.map(error => {
            // console.log(error)
            if (error.type == "coin") {
              setCoinError({ status: true, message: error.message })
            } else {
              setEmailError({ status: true, message: error.message })
            }
          })

        } else if (res.code == 200) {
          setBottomShSuccess(true)
          setCoinError({ status: false, message: "length" })

          dispatch(setUserCoin(res.data.user.coins))
        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
          let name = "Token"
          let name1 = "active"
          document.cookie = name + '=; Max-Age=-99999999;';
          document.cookie = name1 + '=; Max-Age=-99999999;';
          history.push("/");
          // dispatch(setLoading(false))

        }

      } else {
        setCoinError({ status: true, message: "Coin count must not be empty" })
      }
    } else {
      setEmailError({ status: true, message: "An account with this email was not found" })
    }
}else{
  setEmailError({ status: true, message: "Destination email must not be empty" })

}
  
  }
  // console.log(CoinError)
  return (
    <div className='TransferContainer'>


      <div className='TransferContainer1'>
        <div className='TransferTextHeader'>
          <div style={{ "marginRight": "4px" }}>
            You can transfer your coins from
          </div>
          <div style={{ "fontWeight": "500" }}>
            '{Email}' &nbsp;
          </div>
          <div>
            to other email accounts
          </div>

        </div>
        <div className='TransferDestination'>
          {EmailError.status == true
            ?
            <TextField
              className="TransferDestinationInput"
              error
              id="outlined-error-helper-text"
              label="Destination Email"
              defaultValue={TargetEmail}
              helperText={EmailError.message}
              onChange={(e) => setDestEmail(e)}
              value={TargetEmail}
            />
            : <TextField id="outlined-basic"
              className="TransferDestinationInput"
              label="Destination Email"
              variant="outlined"
              onChange={(e) => setDestEmail(e)}
              value={TargetEmail}
            />}




        </div>
        <div className='TransferCoinCount'>
          {
            CoinError.status == true ?
              <TextField
                className="TransferDestinationInput"
                style={{ "color": "red !important" }}
                error
                id="outlined-error-helper-text"
                label="Coin count"
                defaultValue={TargetCoin}
                helperText={CoinError.message}
                onChange={(e) => setDestCoin(e)}
                value={TargetCoin}
                type="number"
              />
              :
              <TextField id="outlined-basic"
                label="Coin count"
                className="TransferCoinCountInput"
                variant="outlined"
                onChange={(e) => setDestCoin(e)}
                value={TargetCoin}
                type="number"
              />
          }


        </div>
      </div>


      <div className='TransferContainer2'>
        <div className='TransferButton'>
          <button className='TransferButtonAction' onClick={() => TransferFunction()}>Transfer</button>
        </div>
        <div className='TransferHistory'>
          <Link to="/TransferHistory" className='TransferHistoryAction' ><RestoreIcon className='RestoreIcon' /> Transfer history</Link>
        </div>
      </div>
      <BottomSheet onDismiss={onDismiss} open={BottomShSuccess}>
        <div className='RegisterBeforeBottomSheet'>
          <div className='RegisterBeforeBottomSheetHeader'>
            <div className='SuccessBBottomSheetHeaderText'>
              <VerifiedIcon style={{ "color": "#2E7D32", "marginRight": "8px" }} />
              Successful transfer</div>
          </div>
          <div className='RegisterBeforeBottomSheetBody'>
            {parseInt(TargetCoin).toLocaleString()}  coin transferred to '{TargetEmail}'
          </div>
          <div className='RegisterBeforeBottomSheetFooter'>
            {/* <button className='BottomSheetCancelButton' onClick={() => SuccessB(false)}>Cancel</button> */}
            <button className='BottomSheetSuccessB' onClick={() => setBottomShSuccess(false)}>Got it</button>
          </div>
        </div>
      </BottomSheet>

    </div>
  )
}

export default Transfer