import React, { useEffect } from 'react'
import { useState } from 'react'
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import BackImg from './../Public/Image/Login/Back.svg'
import './RegisterGetPassword.css'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from "axios";
// import { RegisterApi } from './../Api/RegisterApi'
import { AllApi } from './../Api/AllApi'

import Checkbox from '@mui/material/Checkbox';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import ImageBottomSheet from './../Public/Image/Register/BottomSheetIcon.svg'
import { GetCookie } from './../Action/GetCookie'
import { SetCookie } from './../Action/SetCookie'
import { db } from './../DataBase/db'
import { setMiniLoading } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { setTooManyLogin } from './../app/Home'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'
import Dexie from 'dexie';

const RegisterGetPassword = () => {
  const Token = GetCookie('Token')
  let active = GetCookie('active');
  const MiniLoading = useSelector((state) => state.Home.MiniLoading)
  const dispatch = useDispatch()

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Email = searchParams.get('email');
  const Picture = useSelector((state) => state.counter.Picture)

  async function addUserDb() {

    let resdata
    let Status = await Dexie.exists('MembersgramDB').then(async function (exists) {
      if (!exists) {
        // var db = new Dexie('my-database');
        // db.version(1).stores(...);
        // db.open();
        // console.log(exists)
        var newdb = new Dexie('MembersgramDB');
        newdb.version(1).stores({
          Data: '++id, Email,PhoneNumber,Picture'
        });
        db.open();
        const id = await newdb.Data.add({
          Email, Picture
        });
        // Status=true
        // let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
        // let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: Picture })
      } else {
        // console.log(exists)
        // console.log(Picture)
        // const id = await db.Data.add({
        //     Email, Picture
        // });
        let resdata = await db.Data.toArray()
        // console.log(resdata)
        let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
        let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: '' })
        //   return true

      }
      // console.log(Email)
      // console.log(Status)
      return true
    });
    // console.log(Status)
    return Status

    // try {
    //   let resdata = await db.Data.toArray()
    //   if (resdata.length == 0) {
    //     const id = await db.Data.add({
    //       Email
    //     });
    //     console.log(resdata)
    //   }else{
    //     let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
    //     let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ CountryCode: '' })
    //     let res2 = db.Data.where({ id: Number(resdata[0].id) }).modify({ PhoneNumber:''  })

    //   }


    // } catch (error) {
    //   console.log(`Failed to add ${Email}: ${error}`);
    // }
  }
  const Numbersgram = GetCookie('Numbersgram');

  useEffect(() => {
    if (active === 'true') {
      if(Numbersgram=='Numbersgram'){
        history.push(`/Coin?Status=Sale`);

        }else{
          history.push(`/Home`);  
        }

    }
    if (Email) {
      // console.log(Email)
    } else {
      history.push(`/Register`);
    }

  }, [])

  const [password, setPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError1(false)
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value)
    setError2(false)
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };


  const [Error1, setError1] = useState(false)
  const [Error2, setError2] = useState(false)

  const CheckPassword = () => {
    let CheckPass1 = false
    let CheckPass2 = false

    if (password !== '') {
      if (password.length > 7) {
        CheckPass1 = true
        setError1(false)
      } else {
        setError1('password must be at least 8 characters')
      }
    } else {
      setError1('password must not be empty')
    }

    if (ConfirmPassword !== '') {
      if (ConfirmPassword.length > 7) {
        CheckPass2 = true
        setError2(false)
      } else {
        setError2('password must be at least 8 characters')

      }
    } else {
      setError2('password must not be empty')
    }
    if (CheckPass1 && CheckPass2) {
      if (ConfirmPassword === password) {
        setError2(false)
        RegisterApiFunc()
      } else {
        setError2('passwords do not match')
      }

    }
  }
  const RegisterApiFunc = async () => {
    dispatch(setMiniLoading(true))
    let res;
    try {
      res = await axios.get("http://ip-api.com/json");
    } catch (error) {
      console.log(error)
      const MakeApiClass = new AllApi();
      let ClientUser = GetCookie('ClientUser');

      let responseApi = await MakeApiClass.RegisterApi(Email, password, false, 'WebAppP', ClientUser)
      // console.log(responseApi)
      if (responseApi.code === 200) {
        SetCookie("Token", responseApi.data.data.token, 100)
        SetCookie("active", 'true', 100)
        if(Numbersgram=='Numbersgram'){
          history.push(`/Coin?Status=Sale`);

          }else{
            history.push(`/Home`);  
          }
        addUserDb(Email)
      } else if (responseApi.code == 301) {
        setOpen(true)
      } else if (responseApi.code == 120 || responseApi.code == 121 || responseApi.code == 122 || responseApi.code == 123) {
        let name = "Token"
        let name1 = "active"
        document.cookie = name + '=; Max-Age=-99999999;';
        document.cookie = name1 + '=; Max-Age=-99999999;';
        history.push("/");
      }
    }
    // const res = await axios.get("https://geolocation-db.com/json/");


    // console.log(res.data.country_name)

    // register

    // console.log(responseApi)
    // if (responseApi.code === 301) {
    //   setOpen(true)
    // } else if (responseApi.code === 200) {
    //   SetCookie("Token", responseApi.data.data.token, 100)
    // }

    // if (res.data.country_name === "Iran") {
    if (res) {

      console.log(res)
      if (res.data) {
        if (res.data.country === "Iran") {

          // get phone number
          const MakeApiClass = new AllApi();
          let responseApi = await MakeApiClass.RegisterApi(Email, password, true, 'WebAppP')
          // let responseApi = await RegisterApi(Email, password, true, 'WebAppP')
          // console.log(responseApi)
          if (responseApi.code == 429) {
            dispatch(setTooManyLogin(true))
          }
          else
            if (responseApi.code === 200) {
              SetCookie("Token", responseApi.data.data.token, 100)
              SetCookie("active", 'false', 100)
              history.push(`/GetPhoneNumber`);
              addUserDb(Email)

            } else if (responseApi.code == 301) {
              setOpen(true)
            } else if (responseApi.code == 120 || responseApi.code == 121 || responseApi.code == 122 || responseApi.code == 123) {
              let name = "Token"
              let name1 = "active"
              document.cookie = name + '=; Max-Age=-99999999;';
              document.cookie = name1 + '=; Max-Age=-99999999;';
              history.push("/");
              // dispatch(setLoading(false))

            }


        } else {
          // let responseApi = await RegisterApi(Email, password, false, 'WebAppP')
          const MakeApiClass = new AllApi();
          let ClientUser = GetCookie('ClientUser');

          let responseApi = await MakeApiClass.RegisterApi(Email, password, false, 'WebAppP', ClientUser)
          // console.log(responseApi)
          if (responseApi.code === 200) {
            SetCookie("Token", responseApi.data.data.token, 100)
            SetCookie("active", 'true', 100)
            if(Numbersgram=='Numbersgram'){
              history.push(`/Coin?Status=Sale`);

              }else{
                history.push(`/Home`);  
              }
            addUserDb(Email)
          } else if (responseApi.code == 301) {
            setOpen(true)
          } else if (responseApi.code == 120 || responseApi.code == 121 || responseApi.code == 122 || responseApi.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

          }
        }
      }

    }

    dispatch(setMiniLoading(false))

  }
  const [CheckboxState, setCheckboxState] = useState(false)
  const ValidateCheckbox = (e) => {
    setCheckboxState(e.target.checked)
  }
  const [open, setOpen] = useState(false)
  function onDismiss() {
    setTimeout(() => {
      setOpen(false)
    }, 300);
  }


  return (




    <div className='RegisterContainer'>
      <div className='RegisterHeader'>
        <Link to="/Register" className='RegisterBackIcon'>
          <img src={BackImg} />
        </Link>
        <div className='RegisterHeaderText'>
          Register
        </div>
      </div>
      <div className='RegisterBody'>
        <BottomSheet onDismiss={onDismiss} open={open}>
          <div className='RegisterBeforeBottomSheet'>
            <div className='RegisterBeforeBottomSheetHeader'>
              <img src={ImageBottomSheet} />
              <div className='RegisterBeforeBottomSheetHeaderText'>You have registered before</div>
            </div>
            <div className='RegisterBeforeBottomSheetBody'>This Email is already connected to an account, Do you want to log in?</div>
            <div className='RegisterBeforeBottomSheetFooter'>
              <button className='BottomSheetCancelButton' onClick={() => setOpen(false)}>Cancel</button>
              <Link to="/Login" className='BottomSheetLoginButton'>Go to Login</Link>
            </div>
          </div>
        </BottomSheet>
        <div className='RegisterBodyText'>
          Please set a password and confirm it
        </div>
        <div className='RegisterBodyText'>
          <b>Note: </b> password must be at least &nbsp; <b> 8 characters</b>
        </div>
        <div className='RegisterPasswordInput'>
          {
            Error1 === 'password must be at least 8 characters' ?
              <TextField
                error
                fullWidth
                variant="outlined"
                className='RegisterPasswordTextField'
                label="Password"
                id="outlined-error-helper-text"
                helperText="Password must be at least 8 characters"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    password.length > 0 ?
                      <InputAdornment position="end" className='IconShowHideError'>
                        <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                          {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                        </IconButton>
                      </InputAdornment>
                      : null
                  ),
                }}
              />
              :
              Error1 === 'password must not be empty' ?
                <TextField
                  error
                  fullWidth
                  variant="outlined"
                  className='RegisterPasswordTextField'
                  label="Password"
                  id="outlined-error-helper-text"
                  helperText="password must not be empty"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      password.length > 0 ?
                        <InputAdornment position="end" className='IconShowHideError'>
                          <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                            {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                          </IconButton>
                        </InputAdornment>
                        : null
                    ),
                  }}
                />
                :
                <TextField
                  fullWidth
                  variant="outlined"
                  className='RegisterPasswordTextField'
                  label="Password"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      password.length > 0 ?
                        <InputAdornment position="end" className='InputAdornment'>
                          <IconButton onClick={togglePasswordVisibility} className='IconShowHide'>
                            {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                          </IconButton>
                        </InputAdornment>
                        : null
                    ),
                  }}
                />
          }

          {Error2 === 'password must be at least 8 characters' ?
            <TextField
              error
              fullWidth
              variant="outlined"
              className='RegisterPasswordTextField'
              label="Confirm Password"
              id="outlined-error-helper-text"
              type={showConfirmPassword ? 'text' : 'password'}
              value={ConfirmPassword}
              onChange={handleConfirmPasswordChange}
              helperText="Password must be at least 8 characters"
              InputProps={{
                endAdornment: (
                  ConfirmPassword.length > 0 ?
                    <InputAdornment position="end" className='IconShowHideError'>
                      <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                        {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                      </IconButton>
                    </InputAdornment>
                    : null
                ),
              }}
            />

            :
            Error2 === 'password must not be empty' ?
              <TextField
                error
                fullWidth

                variant="outlined"
                className='RegisterPasswordTextField'
                label="Confirm Password"
                id="outlined-error-helper-text"
                type={showConfirmPassword ? 'text' : 'password'}
                value={ConfirmPassword}
                helperText="password must not be empty"
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  endAdornment: (
                    ConfirmPassword.length > 0 ?
                      <InputAdornment position="end" className='IconShowHideError'>
                        <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                          {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                        </IconButton>
                      </InputAdornment>
                      : null
                  ),
                }}
              />
              :
              Error2 === 'passwords do not match' ?
                <TextField
                  error
                  fullWidth
                  variant="outlined"
                  className='RegisterPasswordTextField'
                  label="Confirm Password"
                  id="outlined-error-helper-text"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={ConfirmPassword}
                  helperText="passwords do not match"
                  onChange={handleConfirmPasswordChange}
                  InputProps={{
                    endAdornment: (
                      ConfirmPassword.length > 0 ?
                        <InputAdornment position="end" className='IconShowHideError'>
                          <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHideError'>
                            {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                          </IconButton>
                        </InputAdornment>
                        : null
                    ),
                  }}
                />
                :
                <TextField
                  fullWidth
                  variant="outlined"
                  className='RegisterPasswordTextField'
                  label="Confirm Password"
                  id="Confirmpassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={ConfirmPassword}
                  onChange={handleConfirmPasswordChange}
                  InputProps={{
                    endAdornment: (
                      ConfirmPassword.length > 0 ?
                        <InputAdornment position="end" className='IconShowHide'>
                          <IconButton onClick={toggleConfirmPasswordVisibility} className='IconShowHide'>
                            {showConfirmPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                          </IconButton>
                        </InputAdornment>
                        : null
                    ),
                  }}
                />
          }

        </div>



      </div>
      <TooManyRequestBSh />

      <div className='RegisterFooter'>
        {/* <hr /> */}
        <div className='RegisterFooterLinks'>
          <Checkbox onChange={(e) => ValidateCheckbox(e)} />
          <div>
            Agree to your &nbsp;
            <a href="https://membersgram.com/privacy/" className='PrivacyLink'>
              Privacy Policy
            </a> and &nbsp;
            <a href='https://membersgram.com/privacy/' className='TermLink'>
              Terms of Service.
            </a>

          </div>


        </div>
        <div className='RegisterLinkDiv'>
          <button className={CheckboxState ? "RegisterLink" : "RegisterLinkDisable"} onClick={CheckboxState ? () => CheckPassword() : null}>

            {MiniLoading ?
              <div className="loadminijoin"></div>
              :
              'Register'
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegisterGetPassword