import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import {setBottomSheetViewCheckUsername} from './../app/Home'
import {setBottomSheetViewShowPriceAndChannel} from './../app/Home'

import { useSelector, useDispatch } from 'react-redux'

const ViewCheckUsername = () => {
    const dispatch = useDispatch()
    const BottomSheetViewCheckUsername = useSelector((state) => state.Home.BottomSheetViewCheckUsername)
    const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)

    function onDismiss() {
        setTimeout(() => {
        dispatch(setBottomSheetViewCheckUsername(false))
    }, 300);
    }
 
// console.log(BottomSheetViewCheckUsername)
    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetViewCheckUsername}>
            <div className='RegisterBeforeBottomSheet'>
                <div className='RegisterBeforeBottomSheetHeader'>
                    <div className='RegisterBeforeBottomSheetHeaderText'>@{UserNameForOrder}</div>
                </div>
                <div className='RegisterBeforeBottomSheetBody'>Are you sure @{UserNameForOrder} is the ID you are looking for?</div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button className='BottomSheetCancelButton' onClick={() => dispatch(setBottomSheetViewCheckUsername(false))}>Cancel</button>
                    <button  className='BottomSheetLoginButton' onClick={()=>dispatch(setBottomSheetViewShowPriceAndChannel(true),dispatch(setBottomSheetViewCheckUsername(false)))}>Yes, next</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default ViewCheckUsername