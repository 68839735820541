import React from 'react'
import Back from './../Public/Image/Home/Back.svg'
import './GetLinkUsername.css'
import TextField from '@mui/material/TextField';
import CheckUsername from '../BottomSheet/CheckUsername';
import ShowPriceAndChannel from './../BottomSheet/ShowPriceAndChannel';
import { useState } from 'react';
import { setBottomSheetCheckUsername } from './../app/Home'
import { setUserNameForOrder } from './../app/Home'
import { GetCookie } from '../Action/GetCookie';
// import { CheckChannelBeforePurchase } from './../Api/CheckChannelBeforePurchase'
import { AllApi } from './../Api/AllApi'

import { useSelector, useDispatch } from 'react-redux'
import TooManyOrders from './../BottomSheet/TooManyOrders'
import TooManyOrdersUser from './../BottomSheet/TooManyOrdersUser'
import { setBottomSheetTooManyOrdersUser } from './../app/Home'
import { setBottomSheetTooManyOrdersUserTitle } from './../app/Home'
import { setBottomSheetTooManyOrdersUserDescription } from './../app/Home'

import ViolatingChannel from './../BottomSheet/ViolatingChannel'
import { setBottomSheetTooManyOrders } from './../app/Home'
import { setBottomSheetViolatingChannel } from './../app/Home'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ShowPriceAndChannelCoin from './../BottomSheet/ShowPriceAndChannelCoin'

import { setMiniLoading } from './../app/Home'
import { useHistory } from 'react-router-dom';


const GetLinkUsername = () => {
    const dispatch = useDispatch()
    // const Email = useSelector((state) => state.counter.email)
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)





    const Token = GetCookie('Token')
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ByCoin = searchParams.get('ByCoin');

    const history = useHistory();

    const HandleUsernameLink = async () => {
        dispatch(setMiniLoading(true))
        let result = Username.trim();
        result = result.toLowerCase();
        setUsernameErrorEmpty(false)
        setUsernameError(false)
        let PositionLink = result.search("https://t.me/");
        let PositionSign = result.search("@");
        if (PositionLink == 0) {

            result = result.replace('https://t.me/', '');

        } else if (PositionSign == 0) {

            result = result.replace('@', '');

        } else {
            result = result
        }

        if (result.length > 4) {
            if (isValidTelegramUsername(result)) {
                setUsernameError(false)

            } else {

                setUsernameError(true)
            }
        } else {
            setUsernameError(false)

        }



        if (result.length == 0) {
            setUsernameErrorEmpty(true)
            dispatch(setMiniLoading(false))

        } else {
            if (UsernameError == false && UsernameErrorEmpty == false) {
                if (result.length < 4 && result.length >= 0) {
                    setUsernameError(true)
                } else {
                    const MakeApiClass = new AllApi();
                    let res = await MakeApiClass.CheckChannelBeforePurchase(Token, result)
                    // let res = await CheckChannelBeforePurchase(Token, result)
                    // console.log(res)
                    if (res.code == 200) {
                        dispatch(setBottomSheetCheckUsername(true))
                        dispatch(setUserNameForOrder(result))
                    } else if (res.code == 304) {
                        dispatch(setBottomSheetTooManyOrders(true))
                    } else if (res.code == 303) {
                        // setBottomSheetViolatingChannel
                        dispatch(setBottomSheetViolatingChannel(true))

                    } else if (res.code == 305) {
                        // username format
                        setUsernameError(true)
                    }else if (res.code == 306) {
                        // username format
                        // console.log(res)
                        dispatch(setBottomSheetTooManyOrdersUserTitle(res.message.title)) 
                        dispatch(setBottomSheetTooManyOrdersUserDescription(res.message.description)) 
                        
                        dispatch(setBottomSheetTooManyOrdersUser(true))
                    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                        let name = "Token"
                        let name1 = "active"
                        document.cookie = name + '=; Max-Age=-99999999;';
                        document.cookie = name1 + '=; Max-Age=-99999999;';
                        history.push("/");
                        // dispatch(setLoading(false))

                    }

                }
                dispatch(setMiniLoading(false))
            }


        }


    }
    useEffect(() => {
        // dispatch(setBottomSheetTooManyOrders(true))
        // dispatch(setBottomSheetViolatingChannel(true))

    }, [])






    const [LastUsername, setLastUsername] = useState('')
    const [Username, setUsername] = useState('')
    const [UsernameError, setUsernameError] = useState(false)
    const [UsernameErrorEmpty, setUsernameErrorEmpty] = useState(false)
    // const telegramUsernameRegex = /^[a-zA-Z][a-zA-Z0-9_]{3,31}$/;
    const telegramUsernameRegex = /^[a-zA-Z][a-zA-Z0-9_]{3,30}[a-zA-Z0-9]$/;

    function isValidTelegramUsername(username) {
        return telegramUsernameRegex.test(username);
    }
    const HandleChange = (value) => {
        setUsername(value)
        setUsernameErrorEmpty(false)
        setUsernameError(false)
    }

    return (
        <div className='GetLinkUsername'>
            <div className='GetLinkUsernameHeader'>
                <Link to="/Home" className="GetLinkUsernameHeaderLink">  <img src={Back} className='GetLinkUsernameHeaderImg' />
                </Link>
                <div className='GetLinkUsernameHeaderText'>
                    Username or Link
                </div>
            </div>
            <div className='GetLinkUsernameContent'>
                <div className='GetLinkUsernameContentText1'>
                    Please enter your channel/group username or link
                </div>
                <div className='GetLinkUsernameInputContainer'>
                    {

                        UsernameErrorEmpty ?
                            <TextField
                                error
                                id="outlined-error"
                                label="Username or Link"
                                defaultValue="Hello World"
                                className='GetLinkUsernameInput'
                                onChange={(e) => HandleChange(e.target.value)}
                                value={Username}
                                helperText="field is empty"
                            />
                            :
                            UsernameError ?
                                <TextField
                                    error
                                    id="outlined-error"
                                    label="Username or Link"
                                    defaultValue="Hello World"
                                    className='GetLinkUsernameInput'
                                    onChange={(e) => HandleChange(e.target.value)}
                                    value={Username}
                                    helperText="format is incorrect"
                                />
                                : <TextField id="outlined-basic"
                                    label="Username or Link"
                                    variant="outlined"
                                    className='GetLinkUsernameInput'
                                    // onChange={(e) => HandleChange(e.target.value)}
                                    onChange={(e) => HandleChange(e.target.value)}
                                    value={Username}

                                />
                    }


                </div>
                <div className='GetLinkUsernameContentText'>
                    Please ensure the following before placing an order:
                </div>
                <ul className='GetLinkUsernameContentTextUl'>
                    <li>
                        The channel/group must be public
                    </li>
                    <li>
                        Turn off approve new members in group
                    </li>
                </ul>
            </div>
            <div className='GetLinkUsernameFooter'>
                <button className='GetLinkUsernameFooterButton' onClick={() => HandleUsernameLink()}>
                    {MiniLoading ?
                        <div className='LoaderContainer'>
                            <div className="loadminijoin"></div>
                        </div>

                        :
                        'Next'
                    }
                </button>
            </div>
            {
                ByCoin == 'true' ? <ShowPriceAndChannelCoin /> : <ShowPriceAndChannel />
            }
            <CheckUsername />

            <TooManyOrders />
            <TooManyOrdersUser />
            
            <ViolatingChannel />
        </div>
    )
}

export default GetLinkUsername