import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { useSelector, useDispatch } from 'react-redux'

import SuccessPayment from './../Public/Image/Home/SuccessPayment.svg'
import {setBottomSheetsuccessfulPayment} from './../app/Home'
import { useLocation } from 'react-router-dom';

const SuccessfulPayment = () => {
    const dispatch = useDispatch()
    // const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)
    const BottomSheetsuccessfulPayment = useSelector((state) => state.Home.BottomSheetsuccessfulPayment)
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const Count = searchParams.get('count');
    const UserName = searchParams.get('username');
    const PaymentReturn = searchParams.get('payment');
    const PaymentReturnView = searchParams.get('paymentview');

    function onDismiss() {
        setTimeout(() => {
        dispatch(setBottomSheetsuccessfulPayment(false))
    }, 300);
    }


    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetsuccessfulPayment}>
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='successfulPaymentItem'>
                       
                        <div className='UnsuccessfulPaymentValue'>
                            <img src={SuccessPayment} className='UnsuccessfulPaymentImg'/> 
                            successful payment</div>
                            <div className='SuccessfulPaymentText'>
                            {Count} {PaymentReturn === 'true' ?'members':PaymentReturnView==='true'?'View':null} are sending to <span style={{"font-weight":"bold"}}>{UserName}</span>
                            </div>
                    </div>
                   
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button  className='BottomSheetShowPriceAndChannelButton' onClick={()=>onDismiss()}>Got it</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default SuccessfulPayment