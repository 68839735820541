import Dexie from 'dexie';

export const TelegramDB = new Dexie('TelegramDB');
TelegramDB.version(1).stores({
  Data: `++id,
   Session,
   PhoneNumber,
   User,
   ProfilePhoto,
   BotSession,
   ListForJoin,
   CountryName,
   MyClient ,
   FirstTime
   `
});


