import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Tab: ['Free', 'Buy','Sale', 'Transfer', 'Gift'],
  ActiveTab: 'Free',
  TelegramAccount: null,
  UserTelegramPasswordStatus: false,
  TelegramPassword: '',
  TelegramCode: null,
  ChannelTitle: null,
  ChannelUsername: null,
  ChannelTGID: null,
  ChannelImage: null,
  CountJoining: 0,
  AccountIsFilled: [],
  TooMuchLeft: [],
  AutoJoinLocal:[],

}

export const JoinState = createSlice({
  name: 'JoinState',
  initialState,
  reducers: {
    SetActiveTab: (state, action) => {
      state.ActiveTab = action.payload
    },
    setTelegramAccount: (state, action) => {
      state.TelegramAccount = action.payload
    },
    SetUserTelegramPasswordStatus: (state, action) => {
      state.UserTelegramPasswordStatus = action.payload
    },
    SetTelegramPassword: (state, action) => {
      state.TelegramPassword = action.payload
    },
    SetTelegramCode: (state, action) => {
      state.TelegramCode = action.payload
    },
    SetChannelTitle: (state, action) => {
      state.ChannelTitle = action.payload
    },
    SetChannelUsername: (state, action) => {
      state.ChannelUsername = action.payload
    },
    SetChannelTGID: (state, action) => {
      state.ChannelTGID = action.payload
    },
    
    SetChannelImage: (state, action) => {
      state.ChannelImage = action.payload
    },
    SetCountJoining: (state, action) => {
      state.CountJoining = action.payload
    },
    SetAccountIsFilled: (state, action) => {
      state.AccountIsFilled = action.payload
    },
    SetTooMuchLeft: (state, action) => {
      state.TooMuchLeft = action.payload
    },
    setAutoJoinLocal:(state,action)=>{
      state.AutoJoinLocal.push(action.payload)
      // state.AutoJoinLocal=action.payload
    },


  },
})

// Action creators are generated for each case reducer function
export const { SetActiveTab, setTelegramAccount,SetChannelTGID
  ,SetUserTelegramPasswordStatus, SetTelegramPassword,
  SetTelegramCode, SetChannelTitle, SetChannelUsername,
   SetChannelImage, SetCountJoining,SetAccountIsFilled,SetTooMuchLeft,setAutoJoinLocal } = JoinState.actions
export default JoinState.reducer