import React from 'react'
import './Gift.css'
import GiftIcon from './../Public/Image/Gift/Gift.svg'
import Arrow from './../Public/Image/Gift/Arrow.svg'
import { useHistory } from 'react-router-dom';

const Gift = () => {
  const history = useHistory();

  const GetGiftCode=()=>{
    history.push(`/GiftCode`);

  }
  return (
    <div className='GiftContainer'>
      <div className='GiftInner' onClick={()=>GetGiftCode()}>

        <div className='GiftTextIcon'>
          <div>
            <img src={GiftIcon} />
          </div>
          <div className='GiftText'>Gift Code</div>
        </div>

        <div className='GiftArrow'>
          <img src={Arrow} />
        </div>

      </div>
    </div>
  )
}

export default Gift