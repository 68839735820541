
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './SaleTwoStepBottomSheet.css'
import { SetSaleTwoStepBottomSheet } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';

const SaleTwoStepBottomSheet = () => {

    const history = useHistory();

    const dispatch = useDispatch()
    const SaleTwoStepBottomSheet = useSelector((state) => state.Home.SaleTwoStepBottomSheet)

    const onDismiss = () => {
        setTimeout(() => {
            dispatch(SetSaleTwoStepBottomSheet(false))
        }, 300);
    }
const CloseAndRddirect=()=>{
    dispatch(SetSaleTwoStepBottomSheet(false))
    history.push(`/SellingPhoneNumbers`);

}

    return (
        <BottomSheet onDismiss={onDismiss} open={SaleTwoStepBottomSheet}>
            <div className='SaleTwoStepBottomSheet'>
                <div className='SaleTwoStepBottomSheetTitle'>
                    Two step verification
                </div>
                <div className='SaleTwoStepBottomSheetDes'>
                    If your account has Two Step Verification, please go to telegram, delete it, and then register again
                </div>

                <div className='SaleTwoStepBottomSheetFooter'>

                    <button className='TerminatingSessionsButton'
                        onClick={() => CloseAndRddirect()}
                    >
                        OK
                    </button>

                </div>
            </div>
        </BottomSheet>
    )
}

export default SaleTwoStepBottomSheet