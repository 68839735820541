import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { setInsufficientBalance } from './../app/Home'

import { useSelector, useDispatch } from 'react-redux'
const InsufficientBalance = () => {
    const dispatch = useDispatch()

    const InsufficientBalance = useSelector((state) => state.Home.InsufficientBalance)

    function onDismiss() {
        setTimeout(() => {

        dispatch(setInsufficientBalance(false))
    }, 300);
    }
    return (
        <BottomSheet onDismiss={onDismiss} open={InsufficientBalance}>
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='successfulPaymentItem'>

                        <div className='UnsuccessfulPaymentValue Insufficient'>

                            Insufficient Balance </div>
                        <div className='SuccessfulPaymentText'>
                            You don’t have enough coins to buy this bundle.
                        </div>
                    </div>

                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button className='BottomSheetShowPriceAndChannelButton' onClick={() => onDismiss()}>Got it</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default InsufficientBalance