import React from 'react'
import './LoginButton.css'
import { Link } from 'react-router-dom';
const LoginButton = ({}) => {
  return (
    <div>
       <Link to="/Login" className='LoginButton'>Login</Link>
       
    </div>
  )
}

export default LoginButton