import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { setMiniLoading } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import SaleTwoStepBottomSheet from './../BottomSheet/SaleTwoStepBottomSheet'
import SaleBannedUser from './../BottomSheet/SaleBannedUser'

import { useLocation } from 'react-router-dom';
import { SetSaleTwoStepBottomSheet } from './../app/Home'


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BackImg from './../Public/Image/Login/Back.svg'
import './VerificationCode.css'
import Snackbar from '@mui/material/Snackbar';
import { AllApi } from './../Api/AllApi'
import { SetCookie } from './../Action/SetCookie'
import { SaleNumber } from './../DataBase/SaleNumber'
import { phone } from 'phone';
import { Phone } from '@mui/icons-material';

const VerificationCode = () => {
    const dispatch = useDispatch()

    const [Code, setCode] = useState('')
    const [CodeError, setCodeError] = useState(false)
    const [CodeEmpty, setCodeEmpty] = useState(false)
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    const location = useLocation();
    const [SnackbarState, setSnackbarState] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState(false);
    };
    const searchParams = new URLSearchParams(location.search);
    const PhoneNumber = searchParams.get('number');
    useEffect(() => {
        if (PhoneNumber == null || PhoneNumber == 'null') {
            history.push(`/Coin?Status=Sale`);

        }
    }, [])

    const [PhoneNumberInvalid, setPhoneNumberInvalid] = useState(false)
    const handleCode = (value) => {
        setCodeError(false)
        setCode(value)
        setCodeEmpty(false)

        // console.log(value)
        localStorage.setItem('Code', value)

    }
    // console.log(PhoneNumber)
    const SendCodeFunction = async () => {
        // history.push(`/VerificationCode?number=Free`);


        //   setCountryError(true)

        // console.log(Code)
        let VerifyCode = Code.trim(); // "_xxx"
        // console.log(VerifyCode)

        if (PhoneNumber != null || PhoneNumber != "") {
            if (VerifyCode != "") {
                if (PhoneNumber.length > 0 && VerifyCode.length > 0) {
                    if (VerifyCode.length > 0) {
                        dispatch(setMiniLoading(true))
                        let p = PhoneNumber.replace(" ", ""); // "_xxx"
                        // console.log(p)
                        // console.log(VerifyCode)
                        const MakeApiClass = new AllApi();
                        let res = await MakeApiClass.GetSellCode(p, VerifyCode)
                        //  let res=await GetSellPhoneNumber(CountryCode+PhoneNumber)
                        // console.log(res)


                        if (res.code == 200) {
                            let Now = new Date().getTime();
                            // console.log(Now)
                            let LastTime = Now + 300000;
                            // console.log(LastTime)
                            let PhoneTest = phone(p)
                            // console.log(PhoneTest)
                            const id = await SaleNumber.Data.add({
                                PhoneNumber: p,
                                PhoneTest: PhoneTest,
                                LastTime
                            });
                            history.push(`/Coin?Status=Sale&Success=true`);

                        } else if (res.code == 301) {
                            // Invalid code, please enter a valid code
                            setCodeError(true)
                        }
                        else if (res.code == 302) {
                            // Two step verification
                            dispatch(SetSaleTwoStepBottomSheet(true))



                        } else if (res.code == 303) {
                            // somthing went wrong
                            setSnackbarState(true)
                        }
                    } else {
                        setCodeEmpty(true)
                    }


                }
            } else {
                setCodeEmpty(true)

            }

        } else {

        }

        dispatch(setMiniLoading(false))


    }
    const TochBack = async () => {
        // GetTouchBack()
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.GetTouchBack()
        // console.log(res)
    }
    return (
        <div className='VerificationCode'>
            <div className='VerificationCodeHeader'>
                <Link to="/SellingPhoneNumbers" className="BackIconVerificationCode" onClick={() => TochBack()} >
                    <img src={BackImg} className="RegisterBackIconImg" />

                </Link>
                <div className='BackIconVerificationCodeText'>
                    Verification code
                </div>
            </div>
            <div className='VerificationCodeBody'>

                <div className='VerificationCodeBodyText'

                >
                    Enter the code that was sent to
                    {PhoneNumber}
                </div>
                <div className='LoginTelegramWarning'>
                    {
                        PhoneNumberInvalid ?
                            <TextField
                                error
                                fullWidth
                                variant="outlined"
                                className='RegisterPasswordTextField123'
                                label="PhoneNumberInvalid"
                                id="outlined-error-helper-text"
                                helperText="PhoneNumberInvalid"
                                type='number'
                                value={Code}
                                autoFocus
                                onChange={(e) => handleCode(e.target.value)}

                            />
                            :
                            CodeError ?
                                <TextField
                                    error
                                    fullWidth
                                    variant="outlined"
                                    className='RegisterPasswordTextField123'
                                    label="Verification code"
                                    id="outlined-error-helper-text"
                                    helperText="Invalid code, please enter a valid code"
                                    type='number'
                                    value={Code}
                                    onChange={(e) => handleCode(e.target.value)}
                                    autoFocus
                                />
                                :
                                CodeEmpty ?
                                    <TextField
                                        error
                                        fullWidth
                                        variant="outlined"
                                        className='RegisterPasswordTextField123'
                                        label="Verification code"
                                        id="outlined-error-helper-text"
                                        helperText="Please enter the code"
                                        type='number'
                                        value={Code}
                                        onChange={(e) => handleCode(e.target.value)}
                                        autoFocus
                                    />
                                    :
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        className='RegisterPasswordTextField123'
                                        label="Verification code"
                                        id="Code"
                                        value={Code}
                                        onChange={(e) => handleCode(e.target.value)}
                                        type='number'

                                    />
                    }



                </div>
            </div>
            <div className='SellingPhoneNumbersFooter'>
                <div className='SellingPhoneNumbersLinkDiv'>

                    <button
                        // className={MiniLoading?"SellingPhoneNumbersLinkdisableButton":"SellingPhoneNumbersLink"}
                        className="SellingPhoneNumbersLink"
                        onClick={() => MiniLoading ? null : SendCodeFunction()}

                    >
                        {MiniLoading ?
                            <div className="loadminijoin">

                            </div>
                            :
                            'Registration'

                        }
                    </button>

                </div>



            </div>
            <SaleTwoStepBottomSheet />
            <SaleBannedUser />

            <Snackbar
                open={SnackbarState}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={<span id="client-snackbar"><ErrorOutlineIcon className='ErrorIcon' />Something went wrong! Please try again.</span>}
            // message="Something went wrong! Please try again."

            />
        </div>
    )
}

export default VerificationCode