import packageJson from './../../package.json';
import { AllApi } from './../Api/AllApi'

export async function UpdateCheck() {
    let status=false
    let version=''
    const packageJson1 = packageJson.versioncode
    // console.log(packageJson1)
    const MakeApiClass = new AllApi();
    let CheckUpdateRes = await MakeApiClass.CheckUpdateApi(packageJson1)
// console.log(CheckUpdateRes)
    if (CheckUpdateRes.status == 'success') {
        if (CheckUpdateRes.data.data) {
            status=true
            version=CheckUpdateRes.data.version
        }
    }else{
        status=false
            version=packageJson1  
    }
    return {status,version,'userVersion':packageJson1}
}