import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Login.css'
import BackImg from './../Public/Image/Login/Back.svg'
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// import { LoginApi } from './../Api/LoginApi'
import { AllApi } from './../Api/AllApi'
import { BottomSheet } from 'react-spring-bottom-sheet'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'
import { useHistory } from 'react-router-dom';
import { SetCookie } from './../Action/SetCookie'
import { db } from './../DataBase/db'
import { setMiniLoading } from './../app/Home'
import { setTooManyLogin } from './../app/Home'
// import { DB } from './../DataBase/db'
import { GetCookie } from './../Action/GetCookie'


import { useSelector, useDispatch } from 'react-redux'
import { SetPicture } from './../app/Slice'
// import { SetEmail } from './../app/Slice'
import Dexie from 'dexie';
const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const Picture = useSelector((state) => state.counter.Picture)
    // const TooManyLogin = useSelector((state) => state.Home.TooManyLogin)
    const Numbersgram = GetCookie('Numbersgram');
   
    // const EmailRedux = useSelector((state) => state.counter.Email)
    // const [Picture, setPicture] = useState('')
    const [Email, setEmail] = useState('')
    const HandleEmailChange = (email) => {
        let result = email.trim();
        result = result.toLowerCase();

        setEmail(result)
        setEmailEmpty(false)
        setEmailFormat(false)
        setEmailIncorrect(false)
        setRegisteredBefore(false)
    }
    const [Password, setPassword] = useState('')
    const HandlePasswordChange = (password) => {
        let result = password.trim();

        setPassword(result)
        setPasswordEmpty(false)
        setPasswordIncorrect(false)
        setPasswordLength(false)
    }
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [EmailEmpty, setEmailEmpty] = useState(false)
    const [EmailFormat, setEmailFormat] = useState(false)
    const [PasswordEmpty, setPasswordEmpty] = useState(false)
    const [PasswordLength, setPasswordLength] = useState(false)
    const [PasswordIncorrect, setPasswordIncorrect] = useState(false)
    const [EmailIncorrect, setEmailIncorrect] = useState(false)
    const [InvalidValue, setInvalidValue] = useState(false)
    const [RegisteredBefore, setRegisteredBefore] = useState(false)
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const CheckUserNamePassword = async () => {
        dispatch(setMiniLoading(true))
        setPasswordEmpty(false)
        setPasswordLength(false)
        setPasswordIncorrect(false)
        let status = true

        if (Email.length == 0) {
            setEmailEmpty(true)
            status = false
        }

        let Validate = validateEmail(Email)
        if (Validate == null) {
            setEmailFormat(true)
            status = false
        }
        if (Password.length == 0) {
            setPasswordEmpty(true)
            status = false
        }
        if (Password.length > 0) {
            if (Password.length < 8) {
                setPasswordLength(true)
                status = false
            }
        }

        if (status) {
        let ClientUser = GetCookie('ClientUser');

            // let LoginRes = await LoginApi(Email, Password)
            const MakeApiClass = new AllApi();
            // console.log(Email)
            let LoginRes = await MakeApiClass.LoginApi(Email, Password,ClientUser)
            // console.log(LoginRes.data.data.sessions)
            // console.log(LoginRes)
            // console.log(LoginRes.data.data.sessions.length)
    
            
            if (LoginRes.code == 429) {
                dispatch(setTooManyLogin(true)) 
                
            }
            else if (LoginRes.code == 200) {
        if(LoginRes.data.data.sessions.length > 0){
               localStorage.setItem('SessionFromApi',JSON.stringify(LoginRes.data.data.sessions)) 
            }
                if (LoginRes.data.data.active === true) {
                    // console.log(Email)
                    let res = await addUserDb(Email)
                    // console.log(res)
                    if (res) {
                        SetCookie('Token', LoginRes.data.data.token, 100);
                        SetCookie('active', 'true', 100);
                        dispatch(SetPicture(LoginRes.data.data.picture))
                        // const Picture=LoginRes.data.data.picture
                        // dispatch(SetEmail(Email))
                    //    alert(Numbersgram)
                        if(Numbersgram=='Numbersgram'){
                            history.push(`/Coin?Status=Sale`);
            
                            }else{
                              history.push(`/Home`);  
                            }
                       
                    }

                } else {

                    let res = await addUserDb(Email)
                    if (res) {
                        SetCookie('Token', LoginRes.data.data.token, 100);
                        SetCookie('active', 'false', 100);
                        history.push(`/GetPhoneNumber`);
                    }


                }


            }

            if (LoginRes.code == 300) {
                // console.log('invalid value')
                setInvalidValue(true)
            }
            if (LoginRes.code == 301) {
                // console.log('قبلا لاگین نبود')
                // setEmailIncorrect(true)
                setRegisteredBefore(true)
            }
            if (LoginRes.code == 302) {
                setPasswordIncorrect(true)
                // console.log('پسورد اشتباه است')
            }
            else if (LoginRes.code == 120 || LoginRes.code == 121 || LoginRes.code == 122 || LoginRes.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))

            }
        } else {
            setInvalidValue(true)
        }
        dispatch(setMiniLoading(false))

    }
    async function addUserDb() {
        // console.log(Email)
        // console.log(Email, Picture)
        // try {
        //     const id = await db.Data.add({
        //         Email, Picture 
        //     });

        // } catch (error) {
        //     console.log(`Failed to add ${Email}: ${error}`);
        // }

        let resdata
        let Status = await Dexie.exists('MembersgramDB').then(async function (exists) {
            if (!exists) {
                // var db = new Dexie('my-database');
                // db.version(1).stores(...);
                // db.open();
                // console.log(exists)
                var newdb = new Dexie('MembersgramDB');
                newdb.version(1).stores({
                    Data: '++id, Email,PhoneNumber,Picture'
                });
                db.open();
                const id = await newdb.Data.add({
                    Email, Picture
                });
                // Status=true
                // let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
                // let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: Picture })
            } else {
                // console.log(exists)
                // const id = await db.Data.add({
                //     Email, Picture
                // });
                // let resdata = await db.Data.toArray()
                // console.log(resdata)
                // let res = db.Data.where({ id: Number(resdata[0].id) }).modify({ Email: Email })
                // let res1 = db.Data.where({ id: Number(resdata[0].id) }).modify({ Picture: Picture })
                //   return true
                db.Data.clear();
                var newdb = new Dexie('MembersgramDB');
                newdb.version(1).stores({
                    Data: '++id, Email,PhoneNumber,Picture'
                });
                db.open();
                const id = await newdb.Data.add({
                    Email, Picture
                });
            }
            // console.log(Email)
            // console.log(Status)
            return true
        });
        // console.log(Status)
        return Status
    }
    useEffect(() => {
        if (Email.length > 0) {
            addUserDb(Email)

            if (Picture && Picture.length > 0) {
                addUserDb(Picture)
            }
        }
    }, [Picture])



//     const [isKeyboardVisible, setKeyboardVisible] = useState(false);

//     const keyboardDidShowListener = Keyboard.addListener(
//         'keyboardDidShow',
//         () => {
//           setKeyboardVisible(true); // or some other action
//         }
//       );
//       const keyboardDidHideListener = Keyboard.addListener(
//         'keyboardDidHide',
//         () => {
//           setKeyboardVisible(false); // or some other action
//         }
//       );

// alert(isKeyboardVisible)

    return (
        <div className='LoginContainer'>
            <div className='LoginHeader'>
                <Link to="/" className='LoginBackIcon'>
                    <img src={BackImg} className='LoginBackIconImg' />
                </Link>
                <div className='LoginHeaderText'>
                    Login
                </div>
            </div>
            <div className='LoginBody'>
                <div className='LoginBodyText'>
                    Please enter your Email and password
                </div>
                <div className='LoginEmailInput'>
                    {
                        RegisteredBefore ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                label="Email"
                                value={Email}
                                helperText="This email has not been registered before"
                                className='LoginEmailTextField'
                                onChange={(e) => HandleEmailChange(e.target.value)}
                            />
                            :
                            EmailEmpty ?
                                <TextField
                                    error
                                    id="outlined-error-helper-text"
                                    label="Email"
                                    value={Email}
                                    helperText="Email must not be empty"
                                    className='LoginEmailTextField'
                                    onChange={(e) => HandleEmailChange(e.target.value)}
                                />
                                :
                                EmailFormat ?
                                    <TextField
                                        error
                                        id="outlined-error-helper-text"
                                        label="Email"
                                        value={Email}
                                        helperText="Email format is incorrect"
                                        className='LoginEmailTextField'
                                        onChange={(e) => HandleEmailChange(e.target.value)}
                                    />
                                    :
                                    EmailIncorrect ?
                                        <TextField
                                            error
                                            id="outlined-error-helper-text"
                                            label="Email"
                                            value={Email}
                                            helperText="email is incorrect"
                                            className='LoginEmailTextField'
                                            onChange={(e) => HandleEmailChange(e.target.value)}
                                        />
                                        :
                                        <TextField id="outlined-basic" label="Email" value={Email}
                                            onChange={(e) => HandleEmailChange(e.target.value)}
                                            variant="outlined" className='LoginEmailTextField' />
                    }


                </div>
                <div className='LoginPassInput'>
                    {PasswordEmpty ?

                        <TextField
                            error
                            fullWidth
                            variant="outlined"
                            className='LoginPassTextField'
                            label="Password"
                            id="outlined-error-helper-text"
                            helperText="password must not be empty"
                            type={showPassword ? 'text' : 'password'}
                            value={Password}
                            onChange={(e) => HandlePasswordChange(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    Password.length > 0 ?
                                        <InputAdornment position="end" className='IconShowHideError'>
                                            <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                            </IconButton>
                                        </InputAdornment>
                                        : null
                                ),
                            }}
                        />
                        :

                        PasswordLength ?
                            <TextField
                                error
                                fullWidth
                                variant="outlined"
                                className='LoginPassTextField'
                                label="Password"
                                id="outlined-error-helper-text"
                                helperText="Password must be at least 8 characters"
                                type={showPassword ? 'text' : 'password'}
                                value={Password}
                                onChange={(e) => HandlePasswordChange(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        Password.length > 0 ?
                                            <InputAdornment position="end" className='IconShowHideError'>
                                                <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                    {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                </IconButton>
                                            </InputAdornment>
                                            : null
                                    ),
                                }}
                            />
                            :
                            PasswordIncorrect ?
                                <TextField
                                    error
                                    fullWidth
                                    variant="outlined"
                                    className='LoginPassTextField'
                                    label="Password"
                                    id="outlined-error-helper-text"
                                    helperText="Password is incorrect"
                                    type={showPassword ? 'text' : 'password'}
                                    value={Password}
                                    onChange={(e) => HandlePasswordChange(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            Password.length > 0 ?
                                                <InputAdornment position="end" className='IconShowHideError'>
                                                    <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                        {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null
                                        ),
                                    }}
                                />
                                :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    className='LoginPassTextField'
                                    label="Password"
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={Password}
                                    onChange={(e) => HandlePasswordChange(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            Password.length > 0 ?
                                                <InputAdornment position="end" className='InputAdornment'>
                                                    <IconButton onClick={togglePasswordVisibility} className='IconShowHide'>
                                                        {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                    </IconButton>
                                                </InputAdornment>
                                                : null
                                        ),
                                    }}
                                />
                    }


                </div>
            </div>
            <div className='LoginForgetBlock'>
                <Link className="LoginForgetLink" to="/ForgetPassword">
                    Forgot password?
                </Link>


            </div>

          <TooManyRequestBSh />
            <div className='LoginFooter'>
                <div className='LoginLinkDiv'>
                    <button className="LoginLink" onClick={() => CheckUserNamePassword()}>
                        {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Login'
                        }

                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login