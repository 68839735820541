import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { setTooManyLogin } from './../app/Home'

import { useSelector, useDispatch } from 'react-redux'
const TooManyRequestBSh = () => {
    const dispatch = useDispatch()
    const TooManyLogin = useSelector((state) => state.Home.TooManyLogin)

    // const [TooManyRequest, setTooManyRequest] = useState(false)
    const onDismiss = () => {
        setTimeout(() => {
            dispatch(setTooManyLogin(false)) 
    }, 300);
    }
 

    return (
        <BottomSheet onDismiss={onDismiss} open={TooManyLogin}>
        <div className='TooManyOrders'>
            <div className='TooManyOrdersTitle'>
            Too many  attempts
            </div>
            <div className='TooManyOrdersDes'>
            You are limited, Please try again after getting out of the limit 
            </div>
            <div className='TooManyOrdersFooter'>
                <button className='TooManyOrdersButton' onClick={() => dispatch(setTooManyLogin(false)) } >
                    Got it
                </button>
            </div>
        </div>
    </BottomSheet>
    )
}

export default TooManyRequestBSh