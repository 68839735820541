import Dexie from 'dexie';

export const SaleNumber = new Dexie('SaleNumber');
SaleNumber.version(1).stores({
  Data: `++id,
   PhoneNumber,LastTime
   `
});


