import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './TooManyOrders.css'
import { setBottomSheetTooManyOrders } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
const TooManyOrders = () => {
    const dispatch = useDispatch()
    const BottomSheetTooManyOrders = useSelector((state) => state.Home.BottomSheetTooManyOrders)

    const onDismiss = () => {
        setTimeout(() => {
        dispatch(setBottomSheetTooManyOrders(false))
    }, 300);
    }
    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetTooManyOrders}>
            <div className='TooManyOrders'>
                <div className='TooManyOrdersTitle'>
                    Too many orders in progress
                </div>
                <div className='TooManyOrdersDes'>
                    This channel has 1 order in progress. Please re-order when done
                </div>
                <div className='TooManyOrdersFooter'>
                    <button className='TooManyOrdersButton' onClick={() => dispatch(setBottomSheetTooManyOrders(false))}>
                        Got it
                    </button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default TooManyOrders