
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './SaleTerminatingSessions.css'
import { SetSaleTerminatingSessionsBSh } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
const SaleTerminatingSessions = () => {
    const dispatch = useDispatch()
    const SaleTerminatingSessionsBSh = useSelector((state) => state.Home.SaleTerminatingSessionsBSh)

    const onDismiss = () => {
        setTimeout(() => {
        dispatch(SetSaleTerminatingSessionsBSh(false))
    }, 300);
    }
  
    
  return (
    <BottomSheet onDismiss={onDismiss} open={SaleTerminatingSessionsBSh}>
    <div className='SaleTerminatingSessions'>
        <div className='SaleTerminatingSessionsTitle'>
        Terminating sessions
        </div>
        <div className='SaleTerminatingSessionsDes'>
        Terminate all account sessions except “Hicall”
        </div>
        {/* <div className='TerminatingSessionsList'>
            <ul>
                <li>Niloufar1022</li>
                <li>Niloufar1022</li>
                <li>Niloufar1022</li>
            </ul>
        </div> */}
        <div className='SaleTerminatingSessionsFooter'>
      
            <button className='TerminatingSessionsButton' onClick={()=> dispatch(SetSaleTerminatingSessionsBSh(false))}>
                OK
            </button>
           
        </div>
    </div>
</BottomSheet>
  )
}

export default SaleTerminatingSessions