
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './SaleTerminatingSessions.css'
import { SetSaleTooManyAttempts } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
const SaleTooManyAttempts = () => {
    const dispatch = useDispatch()
    const SaleTooManyAttempts = useSelector((state) => state.Home.SaleTooManyAttempts)

    const onDismiss = () => {
        setTimeout(() => {
        dispatch(SetSaleTooManyAttempts(false))
    }, 300);
    }
  
    
  return (
    <BottomSheet onDismiss={onDismiss} open={SaleTooManyAttempts}>
    <div className='SaleTerminatingSessions'>
        <div className='SaleTerminatingSessionsTitle'>
        Too many attempts
        </div>
        <div className='SaleTerminatingSessionsDes'>
        You are limited, Please try again after getting out of the limit 
        </div>
       
        <div className='SaleTerminatingSessionsFooter'>
      
            <button className='TerminatingSessionsButton' onClick={()=> dispatch(SetSaleTooManyAttempts(false))}>
            Got it
            </button>
           
        </div>
    </div>
</BottomSheet>
  )
}

export default SaleTooManyAttempts