import React, { useEffect } from 'react';

import './Join.css'

import { useSelector, useDispatch } from 'react-redux'

import AddAccount from './AddAccount'

import { setTelegramAccount } from './../app/JoinState'
import { useState } from 'react'
import { RequestToTelegramDb } from './RequestToTelegramDb'
import AccountList from './AccountList'
import Loading from './../Loading'
import { CheckConnection } from './CheckConnection'
import { API_ID, API_HASH } from './../Const'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { TelegramDB } from './../DataBase/TelegramDB'

const Join = () => {


    const dispatch = useDispatch();
    const TelegramAccount = useSelector((state) => state.JoinState.TelegramAccount)
    // const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const [LoadingState, setLoadingState] = useState(true)


    useEffect(() => {


        // setLoadingState(true)

        // console.log(TelegramAccount)
        if (TelegramAccount == null) {
            setLoadingState(false)
        } else if (TelegramAccount.length > 0) {
            setLoadingState(false)
            // console.log(TelegramAccount)
            // console.log(TelegramAccount.length)
        }
    }, [TelegramAccount])

    const GetRequestToTelegramDb = async () => {
        // setLoadingState(true)

        const res = await RequestToTelegramDb()
        // console.log(res)
        // setData(res)
        dispatch(setTelegramAccount(res))



    }
    GetRequestToTelegramDb()

    useEffect(() => {
        let SessionFromApi = localStorage.getItem('SessionFromApi')
        if (SessionFromApi != null) {
            GetAccountFromServer(JSON.parse(SessionFromApi))
        }

    }, [])

    const GetAccountFromServer = async (data) => {
        console.log(data)
        // data.for((item)=>{
        localStorage.removeItem('SessionFromApi')
        for (let index = 0; index < data.length; index++) {

            if (data[index].SaveRes == true) {
                AddTelegramSession(data[index].Session, data[index].ProfilePhoto, data[index].PhoneNumber, data[index].BotSession, data[index].CountryName, data[index].User, data[index].SaveRes)

            }

        }

        // })


    }
    async function AddTelegramSession(Session, ProfilePhoto, PhoneNumber, BotSession, CountryName, User, SaveRes) {
        // const User = JSON.parse(localStorage.getItem('user'));
        // const CountryCode1 = JSON.parse(localStorage.getItem('CountryCode'));
        const FirstTime = true
        try {
            const id = await TelegramDB.Data.add({
                Session, PhoneNumber, User, ProfilePhoto, BotSession, CountryName, FirstTime, SaveRes
            });

        } catch (error) {
            // console.log(`Failed to add ${Session}: ${error}`);
        }
        // }

    }

    return (
        <>

            {
                LoadingState ?
                    <Loading />
                    :
                    TelegramAccount == null ?
                        <Loading />
                        :
                        TelegramAccount.length > 0 ?
                            TelegramAccount.length == 1 ?
                                TelegramAccount[0].BotSession != '' ?
                                    <AccountList TelegramAccount={TelegramAccount} />
                                    :
                                    <AddAccount />


                                :
                                <AccountList TelegramAccount={TelegramAccount} />
                            :
                            <AddAccount />
                // :
                // <AddAccount />
            }
        </>

    )
}

export default Join