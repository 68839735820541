import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import JoinBotImage from './../Public/Image/joinbot/JoinBot.svg'
import { UpdateStateTelegram, SaveSession } from './../app/Slice'
import './JoinBot.css'
// import { FirstRegister } from './../JoinBotApi/FirstRegister'
// import { TokenRegister } from './../JoinBotApi/TokenRegister'
import cryptlib from "cryptlib"
import Snackbar from '@mui/material/Snackbar';
import { TelegramDB } from './../DataBase/TelegramDB'
import { useHistory } from 'react-router-dom';
import { setMiniLoading } from './../app/Home'
import { Link } from 'react-router-dom';
import { AllApi } from './../Api/AllApi'
// import { message } from 'telegram/client'
import { SetCookie } from './../Action/SetCookie'


import { GetCookie } from './../Action/GetCookie'
const JoinBot = () => {
    const history = useHistory();

    const Session = useSelector((state) => state.counter.Session)
    const dispatch = useDispatch()
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)

    let userv = JSON.parse(localStorage.getItem('user'))
    const PhoneNumber = userv.phone
    useEffect(() => {
        if (!Session) {
            // console.log(Session)
            // console.log(JSON.parse(localStorage.getItem('session')))
            dispatch(SaveSession(JSON.parse(localStorage.getItem('session'))))
            // GetFullUserFunc()
        } else {
            // console.log(Session)
            // GetFullUserFunc()

        }
    }, [])

    const StartBotStringFunction = async (Client, userv) => {
        let res
        try {
            const result1 = await Client.invoke(
                new Api.messages.StartBot({
                    bot: "Verify_mem_bot",
                    peer: userv.username ? userv.username : userv.id,
                    startParam: 'hello',
                })
            );
            res = result1
            return res
        } catch (error) {
            res = error
            return 'error'
        }


    }


    const GetUpdatedTelegramAccont = async () => {
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        // setTelegramAccount(TelegramAccountUpdate)
        return TelegramAccountUpdate
    }

    const JoinBotFunction = async () => {
        dispatch(setMiniLoading(true))
        let userv = JSON.parse(localStorage.getItem('user'))
        // console.log(userv)
        const MyID = userv.id;
        let encryptedText
        const iv = '#GV^%^SUR&T*#Y*4'
        const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
        const user = {
            data: {
                phonenumber: userv.phone,
                tg_id: Number(MyID),
                market: 'WebAppP'
            }
        }
        // console.log(user)
        encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
        setShowLinkBack(false)

        // console.log(encryptedText)
        // let res = await FirstRegister(encryptedText)
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.FirstRegister(encryptedText)
        // console.log(res)
        if (res.code == 200) {
            if (res.data.added_coin > 0) {

                // alert('15coin !!!')
                // localStorage.setItem('NewUser', true)
            } else {
                // alert('old user')
                // localStorage.setItem('NewUser', false)
                const Session = JSON.parse(localStorage.getItem('session'))

                const stringSession = new StringSession(Session);
                const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
                    connectionRetries: 3,
                });
                await Client.connect();
                const Me = await Client.getMe()
                // console.log(Me)


                let StartBotString = await StartBotStringFunction(Client, userv)
                // console.log(StartBotString)
                if (StartBotString != 'error') {
                    try {


                        let chat = StartBotString.users[1]

                        // console.log(chat)
                        let ResponseCode = await Client.sendFile('Verify_mem_bot', {
                            file: new Api.InputMediaContact({
                                phoneNumber: userv.phone,
                                firstName: userv.firstName,
                                lastName: userv.lastName ? userv.lastName : '',
                                vcard: `BEGIN:VCARD\nVERSION:3.0\nFN:mas\nTEL;MOBILE:${userv.phone}\nEND:VCARD`
                            })
                        }
                        )

                        // console.log(ResponseCode)
                        //  const msgs = await Client.getMessages(chat, {
                        //                 limit: 1,
                        //             });
                        // let ResponseCode = true
                        if (ResponseCode) {
                            // const messages = await Client.getMessages('Verify_mem_bot', { limit: 1 })
                            let messages = await Client.getMessages('Verify_mem_bot', { limit: 1 })
                            // console.log(messages)

                            if (messages) {
                                let j = 0
                                let i = setInterval(async () => {
                                    if (j < 5) {
                                        //   console.log(messages[0].message.includes('verify code membersgram'))
                                        messages = await Client.getMessages('Verify_mem_bot', { limit: 1 })
                                        if (messages[0].message.includes('verify code membersgram')) {

                                            // console.log(j)
                                            var thenum = messages[0].message.replace(/^\D+/g, ''); // Replace all leading non-digits with nothing
                                            thenum = messages[0].message.match(/\d+/)[0] // "3"

                                            // console.log(messages[0].message)
                                            // console.log(thenum)
                                            const iv = '#GV^%^SUR&T*#Y*4'
                                            const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
                                            const user = {
                                                data: {
                                                    phonenumber: userv.phone,
                                                    verifyCode: thenum
                                                }
                                            }
                                            // console.log(userProfile.phone_number, result)
                                            let encryptedText = cryptlib.encrypt(JSON.stringify(user), key, iv);
                                            const MakeApiClass = new AllApi();
                                            let TokenRegistervar = await MakeApiClass.TokenRegister(encryptedText)
                                            if (TokenRegistervar.code == 200) {
                                                // let TokenRegistervar = await TokenRegister(encryptedText)
                                                // console.log(TokenRegistervar)
                                                localStorage.setItem('BotSession', JSON.stringify(TokenRegistervar.data.data.token))
                                                AddTelegramSession()


                                                let SaveRes = GetCookie('SaveAaccount')
                                                // console.log(SaveRes)
                                                // if (SaveRes == 'true') {
                                                    SetCookie('SaveAaccount', false, 100)
                                                    let res = await GetUpdatedTelegramAccont()
                                                    // console.log(res)
                                                    res.map(async (item) => {
                                                        if (item.PhoneNumber == userv.phone) {
                                                            const MakeApiClass = new AllApi();
                                                            let SessionsAccountRes = await MakeApiClass.SessionsAccount(item.Session, TokenRegistervar.data.data.token, item.User,SaveRes)

                                                            // console.log(SessionsAccountRes)
                                                        }
                                                    })


                                                // } else {
                                                //     console.log(SaveRes)
                                                // }
                                                history.push(`/Coin?Status=Free`);
                                                localStorage.removeItem('BotSession')
                                                localStorage.removeItem('data')
                                                localStorage.removeItem('session')
                                                // localStorage.removeItem('user')


                                                clearInterval(i);
                                            } else if (TokenRegistervar.code == 120 || TokenRegistervar.code == 121 || TokenRegistervar.code == 122 || TokenRegistervar.code == 123) {
                                                let name = "Token"
                                                let name1 = "active"
                                                document.cookie = name + '=; Max-Age=-99999999;';
                                                document.cookie = name1 + '=; Max-Age=-99999999;';
                                                history.push("/");
                                                // dispatch(setLoading(false))

                                            }

                                        }
                                    } else {
                                        // console.log(j)
                                        dispatch(setMiniLoading(false))
                                        handleClick({ vertical: 'bottom', horizontal: 'center' })
                                        setShowLinkBack(true)
                                        clearInterval(i);
                                    }
                                    //   console.log(j)
                                    j++
                                }, 1000);


                            }



                        }
                    } catch (error) {
                        // console.log(error)
                        dispatch(setMiniLoading(false))
                        handleClick({ vertical: 'bottom', horizontal: 'center' })
                        setShowLinkBack(true)
                    }

                } else {
                    // console.log(123)
                    dispatch(setMiniLoading(false))
                    handleClick({ vertical: 'bottom', horizontal: 'center' })
                    setShowLinkBack(true)
                }



            }
        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        } else {
            handleClick({ vertical: 'bottom', horizontal: 'center' })
            setShowLinkBack(true)
        }
        dispatch(setMiniLoading(false))

    }
    async function AddTelegramSession() {
        const BotSession = JSON.parse(localStorage.getItem('BotSession'))

        // console.log(Session, PhoneNumber, User)
        // console.log(ProfilePhoto)
        try {
            // const id = await TelegramDB.Data.put({
            //     'BotSession':BotSession,
            // });
            TelegramDB.Data.where({ PhoneNumber: PhoneNumber }).modify({ BotSession: BotSession })

            // TelegramDB.Data.where({PhoneNumber: PhoneNumber}).modify(item => ++item.quantity);


        } catch (error) {
            // console.log(`Failed to add ${Session}: ${error}`);
        }
    }
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const [ShowLinkBack, setShowLinkBack] = useState(false)
    return (
        <div className='JoinBotContainer'>
            <div className='JoinBot'>

                <div className='JoinBotHeader'>
                    <div>
                        <img src={JoinBotImage} />
                    </div>
                    <div className='JoinBotHeaderTitle'>
                        Join the bot
                    </div>
                    <div className='JoinBotHeaderDes'>
                        By tapping on Join button you will join Membersgram official bot in order to increase your account security
                    </div>
                </div>

                <div className='JoinBotFooter'>
                    {ShowLinkBack ? <Link to={`/LoginTelegram`} className='JoinBotFooterLink'>

                        Change the number
                    </Link> : null}

                    <button className='JoinBotFooterButton' onClick={() => JoinBotFunction()}>
                        {MiniLoading ?
                            <div className='LoaderContainer'>
                                <div className="loadminijoin"></div>
                            </div>

                            : 'Join and get started'}
                    </button>
                </div>

            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                // message={` Password changed`}
                key={vertical + horizontal}
                autoHideDuration={1000}
                message={
                    <span id="client-snackbar"> somthing went wrong</span>}

            />
        </div>
    )
}

export default JoinBot