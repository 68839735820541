import React from 'react'
import Coin from './../Public/Image/Join/Coin.svg'
import CoinBig from './../Public/Image/Home/Coin.svg'

import { useEffect } from 'react'
import './CoinHeader.css'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from '../Action/GetCookie'
import { setUserCoin } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { db } from './../DataBase/db'
import { useHistory } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'

const CoinHeaderForManualJoin = () => {
    const dispatch = useDispatch()
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const history = useHistory();

    const UserCoin = useSelector((state) => state.Home.UserCoin)
    const Token = GetCookie('Token');
    const GetProfileFunction = async () => {
 const MakeApiClass = new AllApi();
        
        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
        // console.log(TelegramAccountUpdatelimit)
        if(TelegramAccountUpdatelimit.length >0){
            let arr=[]
            TelegramAccountUpdatelimit.map(item=>{
                arr.push(item.BotSession)
            })
            // console.log(arr)
             let restransfer=await MakeApiClass.accountTransfer(arr)
            //  console.log(restransfer)
             arr=[]
        }
       


       

      

        let res=await MakeApiClass.GetProfile(Token)
        // let res = await GetProfile(Token)
        // console.log(res)
        if (res.code == 200) {
            dispatch(setUserCoin(res.data.user.coins))
            let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })
            dispatch(SetEmail(res.data.user.email))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }

    }

    useEffect(() => {
        GetProfileFunction()
    }, [UserCoin])

    return (
        <div className='CoinHeader' style={{'maxWidth':'769px','margin':'auto'}}>
            <div className='HeaderLogo'>
                &nbsp;
            </div>
            <div className='HeaderCoin'>
                <div>
                    {UserCoin.toLocaleString()}
                </div>
                <div className='HeaderCoinImgContainer'>
                    {
                        CountJoining > 0 ?
                            <>
                                <div className='CoinHeaderImageLoader'></div>
                                <img src={Coin} className='HeaderCoinImg1' />
                            </>

                            :
                            <img src={Coin} className='HeaderCoinImg1' />

                    }

                </div>

            </div>
        </div>
    )
}

export default CoinHeaderForManualJoin