import React from 'react'
import BackImg from './../Public/Image/Login/Back.svg'
import { Link } from 'react-router-dom';
import './ForgetPassword.css'
import TextField from '@mui/material/TextField';
import { useState } from 'react';
// import {ForgetPasswordApi} from './../Api/ForgetPasswordApi'
import { AllApi } from './../Api/AllApi'
import { setTooManyLogin } from './../app/Home'
import TooManyRequestBSh from './../BottomSheet/TooManyRequestBSh'
import { useHistory } from 'react-router-dom';
import { setMiniLoading } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
const ForgetPassword = () => {
    const history = useHistory();
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const dispatch = useDispatch()

    const [Email, setEmail] = useState('')
    const HandleEmailChange = (email) => {
        let result = email.trim();
        result=result.toLowerCase();
        setEmail(result)
        // setEmail(email)
        setEmailEmpty(false)
        setEmailFormat(false)
        setEmailNotFound(false)

    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const [EmailEmpty, setEmailEmpty] = useState(false)
    const [EmailFormat, setEmailFormat] = useState(false)
    const [EmailNotFound, setEmailNotFound] = useState(false)
    const CheckUserName = async() => {
        dispatch(setMiniLoading(true))

        let status = true
        if (Email.length == 0) {
            setEmailEmpty(true)
            status = false
        }
        let Validate = validateEmail(Email)
        if (Validate == null) {
            setEmailFormat(true)
            status = false
        }
        if(status == true){
            const MakeApiClass = new AllApi();
            let Res=await MakeApiClass.ForgetPasswordApi(Email)
            // let Res =await ForgetPasswordApi(Email)
            // console.log(Res)
            if (Res.code == 429) {
                dispatch(setTooManyLogin(true)) 
            }else if(Res.code === 200){
                history.push(`/ForgetPasswordGetCode?email=${Email}`);
            }else if(Res.code == 301){
                setEmailNotFound(true)
            }
            else if (Res.code == 120 || Res.code == 121 || Res.code == 122 || Res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))
    
            }
        }
        dispatch(setMiniLoading(false))

    }
    return (
        <div className='ForgetPasswordContainer'>
            <div className='ForgetPasswordHeader'>
                <Link to="/Login" className='ForgetPasswordBackIcon'>
                    <img src={BackImg} className='ForgetPasswordBackIconImg'/>
                </Link>
                <div className='ForgetPasswordHeaderText'>
                    Enter email
                </div>
            </div>
            <div className='ForgetPasswordBody'>
                <div className='ForgetPasswordBodyText'>
                Please enter your Email
                </div>
                <div className='LoginEmailInput'>
                    {
                        EmailEmpty ?
                            <TextField
                                error
                                id="outlined-error-helper-text"
                                label="Email"
                                value={Email}
                                helperText="Email must not be empty"
                                className='LoginEmailTextField'
                                onChange={(e) => HandleEmailChange(e.target.value)}
                            />
                            :
                            EmailFormat ?
                                <TextField
                                    error
                                    id="outlined-error-helper-text"
                                    label="Email"
                                    value={Email}
                                    helperText="Email format is incorrect"
                                    className='LoginEmailTextField'
                                    onChange={(e) => HandleEmailChange(e.target.value)}
                                />
                                :
                                EmailNotFound ?
                                    <TextField
                                        error
                                        id="outlined-error-helper-text"
                                        label="Email"
                                        value={Email}
                                        helperText="This email has not been registered before"
                                        className='LoginEmailTextField'
                                        onChange={(e) => HandleEmailChange(e.target.value)}
                                    />
                                    :
                                <TextField id="outlined-basic" label="Email" value={Email}
                                    onChange={(e) => HandleEmailChange(e.target.value)}
                                    variant="outlined" className='LoginEmailTextField' />
                    }


                </div>
            </div>
          <TooManyRequestBSh />

            <div className='ForgetPasswordFooter'>
                <div className='ForgetPasswordLinkDiv'>
                    <button className="ForgetPasswordLink" onClick={() => CheckUserName()}>
                    {MiniLoading ?
                            <div className="loadminijoin"></div>
                            :
                            'Send code'
                        }
                    
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword