import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import './Orders.css'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import InProgressIcon from './../Public/Image/Orders/InProgress.svg'
import DoneIcon from './../Public/Image/Orders/DoneIcon.svg'
import StopIcon from './../Public/Image/Orders/StopIcon.svg'
import ReportIcon from './../Public/Image/Orders/ReportIcon.svg'
import ProsessingIcon from './../Public/Image/Orders/prosessing.svg'
import DoneBottom from './../Public/Image/Orders/DoneBottom.svg'
import StopBottom from './../Public/Image/Orders/StopBottom.svg'
import ReportBottom from './../Public/Image/Orders/ReportBottom.svg'
import BackTottop from './../Public/Image/back.svg'
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import BottomNavigation from './../Home/BottomNavigation'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import DoneIcon1 from '@mui/icons-material/Done';
// import { MyOrderApiAll } from './MyOrderApiAll'
import { AllApi } from './../Api/AllApi'

import Loading from './../Loading'
import NoOrder from './../Public/Image/Orders/NoOrder.svg'
import MemberBlue from './../Public/Image/Orders/MemberBlue.svg'
import visibility from './../Public/Image/Orders/visibility.svg'

import { Link } from 'react-router-dom';
import moment from 'moment';

import { GetCookie } from './../Action/GetCookie'
import { useHistory } from 'react-router-dom';

import { TelegramDB } from './../DataBase/TelegramDB'
import { UpdateCheck } from './../Action/UpdateCheck'
import { SetUpdate } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { SetUpdateVersion } from './../app/Home'
// import { Telegram } from '@mui/icons-material';
import { useRef } from 'react';





const Orders = () => {
  const dispatch = useDispatch()

  let AuthState = GetCookie('Token')
  const history = useHistory();

  useEffect(() => {

    if (AuthState == null || AuthState == 'null') {
      history.push(`/`);
    }

    HideSearch()
  }, [AuthState])

  const [StatusBottomSheet, setStatusBottomSheet] = useState(false)
  const [StatusFilter, setStatusFilter] = useState(false)
  function onDismiss() {
    setTimeout(() => {
      setStatusBottomSheet(false)
    }, 300);
  }

  const SetStatusFilterState = (value) => {
    // console.log(value)
    setStatusFilter(value)
    setStatusBottomSheet(false)
    setData([])
    localStorage.setItem("StatusFilterOrder", value)
    GetAllOrders(1)
  }

  const [TypeBottomSheet, setTypeBottomSheet] = useState(false)
  const [TypeFilter, setTypeFilter] = useState(false)

  function onDismiss1() {
    setTimeout(() => {
      setTypeBottomSheet(false)
    }, 300);

  }

  const SetTypeFilterState = (value) => {
    // console.log(value)
    setTypeFilter(value)
    setTypeBottomSheet(false)
    setData([])
    localStorage.setItem("TypeFilterOrder", value)
    GetAllOrders(1)
  }
  const [SearchState, setSearchState] = useState(false)
  const GetSearch = () => {
    setSearchState(true)
  }
  const HideSearch = () => {
    setSearchState(false)
    setData([])
    setInputValue("")
    localStorage.setItem("UserNameFilterOrder", null)
    GetAllOrders(1)

  }
  const [InputValue, setInputValue] = useState('')
  const HandleInputSearch = (e) => {
    setInputValue(e.target.value)
    setData([])
    localStorage.setItem("UserNameFilterOrder", e.target.value)
    GetAllOrders(1)
  }
  useEffect(() => {
    let StatusFilterFromAddress = localStorage.getItem("StatusFilterOrder")
    let TypeFilterFromAddress = localStorage.getItem("TypeFilterOrder")
    // console.log(StatusFilterFromAddress, TypeFilterFromAddress)
    if (StatusFilterFromAddress != null) {
      // console.log(StatusFilterFromAddress)
      setStatusFilter(StatusFilterFromAddress)

    }
    if (TypeFilterFromAddress != null) {
      // console.log(TypeFilterFromAddress)
      setTypeFilter(TypeFilterFromAddress)
    }
    GetAllOrders(1)
  }, [])
  const [Data, setData] = useState([])
  const [Page, setPage] = useState(1)
  const [LoadingState, setLoadingState] = useState(false)
  const [MiniLoadingState, setMiniLoadingState] = useState(false)
  const [EndList, setEndList] = useState(false)
  const GetAllOrders = async (page) => {
    let TelegramAccount = await TelegramDB.Data.toArray()
    // setShowMore(false)
    // console.log(TelegramAccount)
    let phonenumbers = []
    if (TelegramAccount) {
      if (TelegramAccount.length > 0) {
        TelegramAccount.map(item => {
          phonenumbers.push(item.PhoneNumber)
        })
      }
    }
    //  console.log(page) 
    if (page == 1) {
      setLoadingState(true)
    } else {
      setMiniLoadingState(true)
    }


    let username = "";
    let StatusFilterFromAddress = localStorage.getItem("StatusFilterOrder")
    let TypeFilterFromAddress = localStorage.getItem("TypeFilterOrder")
    let UserNameFilterOrder = localStorage.getItem("UserNameFilterOrder")
    // console.log(UserNameFilterOrder)
    if (UserNameFilterOrder != null || UserNameFilterOrder != 'null') {
      if (UserNameFilterOrder) {
        username = UserNameFilterOrder
      }
    }
    // console.log(phonenumbers)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.MyOrderApiAll(page, TypeFilterFromAddress, StatusFilterFromAddress, username, phonenumbers)
    // let res = await MyOrderApiAll(page, TypeFilterFromAddress, StatusFilterFromAddress, username,phonenumbers)
    // console.log(res)
    if (res.code == 200) {
      if (res.data.data.length == 0) {
        setEndList(true)
        // setData('')

      } else {
        setEndList(false)

        if (Data.length > 0) {
          //  setData(prevState => prevState +res.data.data);
          res.data.data.map(item => {
            setData((prevState) => [...prevState, item])
          })


        } else {
          setData(res.data.data)
        }
      }



    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    } else {
      // console.log('error')
    }
    setLoadingState(false)
    setMiniLoadingState(false)


  }
  // console.log(Data)
  // const [ShowTopButton, setShowTopButton] = useState(0)
  let arr=[]
  const handleScroll = (e) => {

    // var scrollArea = document.querySelector("#OrdersContent")
    // scrollArea.style.marginTop = "280px";
    // scrollArea.style.paddingBottom = "20px";
    // handleScrollToTop();
    //  console.log(e.target.scrollTop)

arr.push(e.target.scrollTop)
// console.log(arr[arr.length-1])
// console.log(arr[arr.length-2])

if(arr.length>0){
  if(arr[arr.length-1]< arr[arr.length-2]){
    var pos = window.pageYOffset;
    // console.log(arr)
    // console.log(pos)
    window.scrollTo( 0, 200 ); 
    arr=[]
  }
}

    // window.addEventListener('scroll', function () {
     
    //   if (e.target.scrollTop < 280) {
    //     handleScrollToTop();
    //   }
    // });
  


    if (e.target.scrollTop > 50) {
      setScrollToTop(true)
    } else {
      setScrollToTop(false)

    }
    const bottom = (e.target.scrollHeight - e.target.scrollTop) - 100 <= e.target.clientHeight;

    if (bottom) {


      //  console.log(e.target.scrollTop)  
      // setShowTopButton(e.target.scrollTop)
      // alert(e.target.scrollHeight - e.target.scrollTop)

      // alert(e.target.clientHeight)
      //     alert(bottom)

      // console.log(bottom)
      setPage(prevState => prevState + 1);

      GetAllOrders(Page + 1)


    } else {

      //   console.log(e)
      //   console.log(e.target.scrollTop)  
      //  setShowTopButton(e.target.scrollTop)
    }
  }
  const handleScroll1 = (e) => {

    if (e.target.scrollTop > 50) {
      setScrollToTop(true)
    } else {
      setScrollToTop(false)

    }

  }
  useEffect(() => {
    CheckUpdateAccess()
  }, [])

  async function CheckUpdateAccess() {
    let UpdateRes = await UpdateCheck()
    let DissmissedVersion = GetCookie('DissMissUpdateVersion')
    // console.log(DissmissedVersion)
    // console.log(UpdateRes.version)
    dispatch(SetUpdateVersion(UpdateRes.version))
    if (UpdateRes.status == true && Number(DissmissedVersion) != UpdateRes.version) {
      dispatch(SetUpdate(true))

    }
  }






  const [scrollToTop, setScrollToTop] = useState(false);
  const contentRef = useRef(null); // Ref to access content element
  // const handleScroll1 = () => {
  //   console.log(window.scrollY)
  //   const scrollPosition = window.scrollY;


  //   setScrollToTop(scrollPosition > 50); // Show button after 50px scroll
  // };

  // window.addEventListener('scroll', handleScroll1);
  // useEffect(() => {
  //   console.log(window.scrollY)
  // handleScroll1()


  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [window.scrollY]);

  const handleScrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll specific element
      // setScrollToTop(false)
    }
  };
  return (
    <div className='Orders' id="Orders">

      {SearchState ?
        <div className='OrdersHeaderSearchContainer'>
          <div className='OrdersHeaderSearch'>
            <div className='BackIconSearchOrdersContainer' onClick={() => HideSearch()}>
              <CloseIcon className='BackIconSearchOrders' />
            </div>
            <div className='SearchInputOrdersContainer'>
              <input className='SearchInputOrders' value={InputValue} placeholder='Search' onChange={(e) => HandleInputSearch(e)} />
            </div>

          </div>

        </div>


        : <div className='OrdersHeaderBg'>
          <div className='OrdersHeader'>
            <div className='OrderHeaderText'>Orders</div>
            <div className='IconSearchOrdersContainer'>
              <SearchOutlinedIcon className='OrdersSearchIcon' onClick={() => GetSearch()} />
            </div>
          </div>
        </div>
      }


      <div className='OrdersContent' id='OrdersContent' ref={contentRef} onScroll={(e) => LoadingState ? null : EndList ? handleScroll1(e) : handleScroll(e)} >



        <div className='OrdersFilter'>
          <div className={TypeFilter == "Type" || TypeFilter == '' ? 'OrdersFilterType' : 'OrdersFilterTypeActive'}
            onClick={() => setTypeBottomSheet(true)}>
            {TypeFilter == "Type" || TypeFilter == '' ? null : <DoneIcon1 className='DoneIconMui' />}
            {TypeFilter == '' ? 'Type' : TypeFilter}  <ArrowDropDownRoundedIcon />
          </div>

          <div className={StatusFilter == "Statuses" || StatusFilter == "" ? 'OrdersFilterStatus' : 'OrdersFilterStatusActive'}
            onClick={() => setStatusBottomSheet(true)}>
            {StatusFilter == "Statuses" || StatusFilter == '' ? null : <DoneIcon1 className='DoneIconMui' />}
            {StatusFilter == '' ? "Statuses" : StatusFilter} <ArrowDropDownRoundedIcon />
          </div>
        </div>

        {/* <div className='Scroller' id="Scroller" > */}
        <div className='OrdersList' >

          {

            // Data == '' ?

            // :
            LoadingState ?
              <Loading />
              :
              Data.length > 0 ?

                Data.map((item, i) =>
                  <Link to={`/OrderDetailsSingle?id=${item.id}`} className='OrdersListItem' key={i}>
                    <div style={{ "paddingLeft": "16px" }}>
                      <div className='OrdersListItemUserName'>{item.username.length > 14 ? item.username.slice(0, 15) + '...' : item.username}</div>
                      {item.status == "done" ?
                        <div className='Done'>
                          <img src={DoneIcon} className='DoneIcon' />
                          Done
                        </div>
                        :
                        item.status == "inprocess" ?
                          <div className='InProgress'>
                            <img src={InProgressIcon} className='InProgressIcon' />
                            In process
                          </div>
                          :
                          item.status == "stop" ?
                            <div className='Stop'>
                              <img src={StopIcon} className='StopIcon' />
                              Stop
                            </div>
                            :
                            item.status == "report" ?
                              <div className='Report'>
                                <img src={ReportIcon} className='ReportIcon' />
                                Report
                              </div>
                              : null

                      }

                    </div>
                    <div style={{ "paddingRight": "16px" }}>
                      <div className='OrdersListItemCount'>{parseInt(item.count).toLocaleString()}
                        {item.orderType == "view" ?
                          <VisibilityIcon className='OrdersPersonIcon' />
                          :
                          item.orderType == "member" ?
                            <PersonIcon className='OrdersPersonIcon' />
                            :
                            null
                        }
                      </div>
                      <div className='OrdersListItemTime'>
                        {
                          moment(item.date).calendar(null, {
                            sameDay: '[Today] , HH : MM',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'dddd',
                            lastDay: '[Yesterday] , HH : MM',
                            // lastWeek: '[Last] dddd',
                            lastWeek: 'DD MMM , YYYY',
                            sameElse: 'DD MMM , YYYY'
                          })
                          // moment(item.date, "DD MM YYYY hh:mm:ss", true)

                          // GetTime(item.date)
                          // Date.now()-item.date <= 86400000?"yesterday":new Date(item.date).toLocaleString()
                          // 86400000
                          // new Date(parseInt(item.date, 10)).toString('MM/dd/yy HH:mm:ss')
                          // new Date(parseInt(item.date, 1)).toString('MM/dd/yy')
                        }
                      </div>
                    </div>
                  </Link>
                )

                :
                <div className='NotResultOrder'>
                  <img src={NoOrder} />

                </div>


          }

          {MiniLoadingState ?
            <div className="loadminiBlue" style={{ "marginTop": "16px" }}></div>
            : null}


        </div>
        {/* </div> */}
        {scrollToTop ?

          <button className='top' onClick={handleScrollToTop}>
            <img src={BackTottop} />
          </button>
          :
          <button className='topHidden' onClick={handleScrollToTop}>
            <img src={BackTottop} />
          </button>
        }
      </div>


      <div className='NavContainer'>
        <BottomNavigation />
      </div>

      <BottomSheet onDismiss={onDismiss} open={StatusBottomSheet}>
        <div className='OrdersStatusBottomSheet'>
          <div className='OrdersStatusBottomSheetHeader'>
            Status
          </div>
          <div className='OrdersStatusBottomSheetContent'>

            <div className={StatusFilter == "Statuses" || StatusFilter == false ? 'ActiveStatusFilterTitle' : "UnActiveStatusFilterTitle"} onClick={() => SetStatusFilterState("Statuses")}>
              <div className='MarginLeft24PX'>All status</div>
            </div>

            <div className={StatusFilter == "Prosessing" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetStatusFilterState("Prosessing")}>
              <img src={ProsessingIcon} className='IconFilterStatus' />
              In process
            </div>

            <div className={StatusFilter == "Done" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetStatusFilterState("Done")}>
              <img src={DoneBottom} className='IconFilterStatus' />
              Done
            </div>

            <div className={StatusFilter == "Stop" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetStatusFilterState("Stop")}>
              <img src={StopBottom} className='IconFilterStatus' />
              Stop
            </div>

            <div className={StatusFilter == "Report" ? "ActiveStatusFilterItem BorderRadiusbottom" : 'UnActiveStatusFilterItem BorderRadiusbottom'} onClick={() => SetStatusFilterState("Report")}>
              <img src={ReportBottom} className='IconFilterStatus' />
              Report
            </div>
          </div>
        </div>
      </BottomSheet >

      <BottomSheet onDismiss={onDismiss1} open={TypeBottomSheet}>
        <div className='OrdersStatusBottomSheet'>
          <div className='OrdersStatusBottomSheetHeader'>
            Type
          </div>
          <div className='OrdersStatusBottomSheetContent'>

            <div className={TypeFilter == "Type" || TypeFilter == false ? 'ActiveStatusFilterTitle' : "UnActiveStatusFilterTitle"} onClick={() => SetTypeFilterState("Type")}>
              <div className='MarginLeft24PX'>All type</div>
            </div>

            <div className={TypeFilter == "Member" ? "ActiveStatusFilterItem" : 'UnActiveStatusFilterItem'} onClick={() => SetTypeFilterState("Member")}>
              <img src={MemberBlue} className='IconFilterStatus' />
              Member
            </div>

            <div className={TypeFilter == "View" ? "ActiveStatusFilterItem BorderRadiusbottom" : 'UnActiveStatusFilterItem BorderRadiusbottom'} onClick={() => SetTypeFilterState("View")}>
              <img src={visibility} className='IconFilterStatus' />
              View
            </div>


          </div>
        </div>
      </BottomSheet >



    </div>
  )
}

export default Orders