import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { useSelector, useDispatch } from 'react-redux'

import ErrorUnSuccess from './../Public/Image/Home/ErrorUnSuccess.svg'
import {setBottomSheetUnsuccessfulPayment} from './../app/Home'

const UnsuccessfulPayment = () => {
    const dispatch = useDispatch()
    const BottomSheetUnsuccessfulPayment = useSelector((state) => state.Home.BottomSheetUnsuccessfulPayment)

    function onDismiss() {
        setTimeout(() => {
        dispatch(setBottomSheetUnsuccessfulPayment(false))
    }, 300);
    }


    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetUnsuccessfulPayment}>
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='ShowPriceAndChannelItem'>
                       
                        <div className='UnsuccessfulPaymentValue'>
                            <img src={ErrorUnSuccess} className='UnsuccessfulPaymentImg'/> 
                            Unsuccessful payment! </div>
                    </div>
                   
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button  className='BottomSheetShowPriceAndChannelButton' onClick={()=>onDismiss()}>Got it</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default UnsuccessfulPayment