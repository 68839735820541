
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './SaleBannedUser.css'
import { SetSaleBannedUserBottomSheet } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import Banned from './../Public/Image/Sale/Banned.webp'

const SaleBannedUser = () => {
    const dispatch = useDispatch()
    const SaleBannedUserBottomSheet = useSelector((state) => state.Home.SaleBannedUserBottomSheet)

    const onDismiss = () => {
        setTimeout(() => {
            dispatch(SetSaleBannedUserBottomSheet(false))
        }, 300);
    }


    return (
        <BottomSheet onDismiss={onDismiss} open={SaleBannedUserBottomSheet}>
            <div className='SaleBannedUserBottomSheet'>
                <div className='SaleBannedUserBottomSheetTitle'>
               <img src={Banned} className='BannedIcon'/> Banned Phone Number
                </div>
                <div className='SaleBannedUserBottomSheetDes'>
                The phone number has been banned. Please enter another number
                </div>

                <div className='SaleBannedUserBottomSheetFooter'>

                    <button className='SaleBannedUserBottomSheetButton'
                        onClick={() => dispatch(SetSaleBannedUserBottomSheet(false))}
                    >
                        OK
                    </button>

                </div>
            </div>
        </BottomSheet>
    )
}

export default SaleBannedUser