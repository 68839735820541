
import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import './SaleTerminatingSessions.css'
import { SetSaleTerminatedSessionsBSh } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
const SaleTerminatedSessions = () => {
    const dispatch = useDispatch()
    const SaleTerminatedSessionsBSh = useSelector((state) => state.Home.SaleTerminatedSessionsBSh)

    const onDismiss = () => {
        setTimeout(() => {
        dispatch(SetSaleTerminatedSessionsBSh(false))
    }, 300);
    }
  
    
  return (
    <BottomSheet onDismiss={onDismiss} open={SaleTerminatedSessionsBSh}>
    <div className='SaleTerminatingSessions'>
        <div className='SaleTerminatingSessionsTitle'>
        Terminated session
        </div>
        <div className='SaleTerminatingSessionsDes'>
        Account session is terminated. Please enter number again
        </div>
       
        <div className='SaleTerminatingSessionsFooter'>
      
            <button className='TerminatingSessionsButton' onClick={()=> dispatch(SetSaleTerminatedSessionsBSh(false))}>
                OK
            </button>
           
        </div>
    </div>
</BottomSheet>
  )
}

export default SaleTerminatedSessions