import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import {setBottomSheetCheckUsername} from './../app/Home'
import {setBottomSheetPriceAndChannel} from './../app/Home'

import { useSelector, useDispatch } from 'react-redux'
const CheckUsername = () => {
    const dispatch = useDispatch()
    const BottomSheetCheckUsername = useSelector((state) => state.Home.BottomSheetCheckUsername)
    const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)

    function onDismiss() {
        setTimeout(() => {
            dispatch(setBottomSheetCheckUsername(false))
        }, 300);
        
    }
 

    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetCheckUsername}>
            <div className='RegisterBeforeBottomSheet'>
                <div className='RegisterBeforeBottomSheetHeader'>
                    <div className='RegisterBeforeBottomSheetHeaderText'>@{UserNameForOrder}</div>
                </div>
                <div className='RegisterBeforeBottomSheetBody'>
                Are you sure 
                <span className='UserNameChannelBold'>@{UserNameForOrder}</span>
                
                 is the username you are looking for?
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button className='BottomSheetCancelButton' onClick={() => dispatch(setBottomSheetCheckUsername(false))}>Cancel</button>
                    <button  className='BottomSheetLoginButton' onClick={()=>dispatch(setBottomSheetPriceAndChannel(true),dispatch(setBottomSheetCheckUsername(false)))}>Yes, next</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default CheckUsername